import {copyLinkToClipboard} from '@/_omicsbox/helpers/FileHelpers'
import {useContext} from 'react'
import {Item, Menu, Separator, Submenu} from 'react-contexify'
import {FileTableContext} from './FileTable'
import useFileTable from './useFileTable'

export const MENU_ID = 'fileTableMenu'

export function FileTableMenu() {
  const fileTableContext = useContext(FileTableContext)
  const {onCopy, onCut, onPaste, filesToCopy, filesToMove, onDownload, onShare, onUnshare} =
    useFileTable(fileTableContext.selectedFiles())
  const isShared = () => {
    return (
      fileTableContext.selectedFiles().length > 0 &&
      fileTableContext.selectedFiles().every((file) => file.shared === true && !file.isDir)
    )
  }
  const isDir = () => {
    return (
      fileTableContext.selectedFiles().length > 0 &&
      fileTableContext.selectedFiles().every((file) => file.isDir)
    )
  }

  return (
    <Menu id={MENU_ID} className='context-menu'>
      <Submenu label='New' arrow='>'>
        <Item onClick={() => fileTableContext.onNewFolder()}>Folder</Item>
      </Submenu>
      <Separator />
      <Item onClick={onCopy} id='context-copy'>
        {'Copy'}
        <div className='contexify_rightSlot'>⌘/Ctrl C</div>
      </Item>
      <Item onClick={onCut} id='context-cut'>
        {'Cut'}
        <div className='contexify_rightSlot'>⌘/Ctrl X</div>
      </Item>
      <Item
        onClick={() => onPaste(fileTableContext.folderPathRef.current)}
        hidden={() => filesToCopy.current.length === 0 && filesToMove.current.length === 0}
        id='context-paste'
      >
        {'Paste'}
        <div className='contexify_rightSlot'>⌘/Ctrl V</div>
      </Item>
      <Item
        onClick={() => {
          fileTableContext.setShowRenameModal(true)
          fileTableContext.onRename()
        }}
        hidden={() => fileTableContext.selectedFiles().length !== 1}
        id='context-rename'
      >
        Rename
      </Item>
      <Separator />
      <Item onClick={onDownload} id='context-download'>
        Download
      </Item>
      <Item onClick={onShare} hidden={() => isShared() || isDir()} id='context-share'>
        Share
      </Item>
      <Item
        onClick={() => copyLinkToClipboard(fileTableContext.selectedFiles())}
        hidden={() => (isShared() ? false : true)}
        id='context-link'
      >
        Copy link
      </Item>
      <Item
        id='context-unshare'
        onClick={() => {
          onUnshare()
          fileTableContext.resetSelection()
        }}
        hidden={() => (isShared() ? false : true)}
      >
        Unshare
      </Item>
      <Separator />
      <Item
        id='context-delete'
        onClick={() => {
          fileTableContext.setShowDeleteConfirmation(true)
        }}
      >
        Delete
      </Item>
    </Menu>
  )
}
