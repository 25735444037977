export const TIMEOUT_PROMISE = 10000;

const RECONNECT_TIMEOUT_MS = 2000;
const RETRY_TIME_MS = 500;

export class WebSocketRequest {
    constructor(args) {
        this.args = args;
        this.reqidMap = {};
        this.promiseMap = {};
        this.setup();
    }

    setup() {
        if (this.args.websocket) {
            this.webSocket = this.args.websocket;
        } else {
            this.webSocket = new WebSocket(this.args.url, this.args.protocols);
        }

        this.webSocket.onmessage = (e) => {
            var eData = JSON.parse(e.data.replace(/\bNaN\b/g, '"."'));
            var cb = this.reqidMap[eData.reqid];
            if (cb != null) {
                delete this.reqidMap[eData.reqid];
                cb(eData.data);
            } else {
                if (this.args.serverCallback != null) {
                    this.args.serverCallback(eData);
                }
            }
        };
        this.webSocket.onopen = (e) => {
            if (this.args.onopen != null) {
                this.args.onopen(e);
            }
        };
        this.webSocket.onclose = (e) => {
            if (this.args.onclose != null) {
                this.args.onclose(e);
            }
        };
        this.webSocket.onerror = (e) => {
            if (this.args.onerror != null) {
                this.args.onerror(e);
            }
        };

        // The 'onclose' does not seem to be triggered when closing the websocket.
        // Surprisingly, this works (it should be the same?)
        this.webSocket.addEventListener('close', function (event) {
            this.webSocket = null
            setTimeout(function () { console.log("Reconnecting"); this.setup() }.bind(this), RECONNECT_TIMEOUT_MS);
        }.bind(this));
    }

    close() {
        this.webSocket.close();
    }

    sendJson(data, cb) {
        var reqId = this.genReqid(cb);
        this.trySend({ reqid: reqId, data: data });
    }

    sendJsonPromise(data) {
        let promiseCbs = {};
        let reqPromise = new Promise((resolve, reject) => {
            promiseCbs.resolve = resolve;
            promiseCbs.reject = reject;

            setTimeout(reject("Timeout"), TIMEOUT_PROMISE);
        });
        let reqId = this.genReqid((msg) => {
            promiseCbs.resolve(msg);
        });

        this.trySend({ reqid: reqId, data: data });

        return reqPromise;
    }

    trySend(reqItem) {
        switch (this.webSocket.readyState) {
            case 1: // OPEN	1	La conexión está abierta y lista para comunicar.
                this.webSocket.send(JSON.stringify(reqItem));
                break;
            case 3: // CLOSED	3	La conexión está cerrada o no puede ser abierta.
                this.setup();
                setTimeout(() => {
                    this.trySend(reqItem);
                }, RETRY_TIME_MS);
                break;
            default:
                // CONNECTING	0	La conexión no está aún abierta.
                // CLOSING 2	La conexión está siendo cerrada.
                setTimeout(() => {
                    this.trySend(reqItem);
                }, RETRY_TIME_MS);
        }
    }

    genReqid(cb) {
        var id = this.randomString(10) + (Date.now() + performance.now()).toString().replace('.', '');
        while (this.reqidMap[id] != null) {
            id = Date.now().toString();
        }
        this.reqidMap[id] = cb;
        return id;
    }

    randomString(length) {
        var characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        length = length || 10;
        var str = "";
        for (var i = 0; i < length; i++) {
            str += characters.charAt(this.randomNumber(0, characters.length - 1));
        }
        return str;
    }

    randomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}