import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {CustomFileData, CustomFileMap, dbItem, FileArray} from '../crud-helper/models'
import {getLogger} from '../logUtils'

const ROOT_FOLDER = ''

function populateBaseFileMap(data: dbItem[] | undefined): CustomFileMap {
  const baseFileMap: CustomFileMap = {}

  if (data) {
    //1.transform the S3ProviderListOutput to a FileArray
    let fileArray: FileArray<CustomFileData> =
      data?.map((item: dbItem) => {
        const isDir = item.file?.endsWith('/')
        const filePathArray = item.file.split('/')
        const name = filePathArray[filePathArray.length - (isDir ? 2 : 1)]
        var parentId = ''
        if (filePathArray.indexOf(name) > 0)
          parentId = filePathArray.slice(0, filePathArray.indexOf(name)).join('/').concat('/')

        const tfileData: CustomFileData = {
          id: item.file,
          name,
          modDate: item.eventTime || '',
          size: item.size || 0,
          isDir,
          children: [],
          parentId,
          shared: item.publicShareId ? true : false,
          shareId: item.publicShareId,
        }
        return tfileData
      }) ?? []

    //2.create tree structure and add to baseFileMap
    //Start by sorting the fileArray folders first
    fileArray = fileArray.sort((a, b) => {
      if (a && b) {
        if (a.isDir && !b.isDir) {
          return -1
        }
        if (!a.isDir && b.isDir) {
          return 1
        }
        return a.id.length - b.id.length
      }
      return 0
    })
    fileArray.forEach((file) => {
      buildMap(file, baseFileMap)
    })
  }

  return baseFileMap
}

function buildMap(file: CustomFileData | null, fileMap: CustomFileMap = {}): CustomFileMap {
  if (file) {
    const parentId = file.parentId || ''
    if (fileMap[file.id]) {
      return fileMap
    }
    //add the file to the map
    fileMap[file.id] = file
    if (file.id === parentId) {
      return fileMap
    }
    //add the file to the parent or create the parent
    if (fileMap[parentId]) {
      fileMap[file.id].parentId = parentId
      if (fileMap[parentId].children) {
        fileMap[parentId].children = [...fileMap[parentId].children!, file]
      } else {
        fileMap[parentId].children = [file]
      }
      return fileMap
    } else {
      const isDir = parentId.endsWith('/')
      const id = parentId
      const filePathArray = id.split('/')
      const name = filePathArray[filePathArray.length - (isDir ? 2 : 1)]
      var idParent = ''
      if (filePathArray.indexOf(name) > 0)
        idParent = filePathArray.slice(0, filePathArray.indexOf(name)).join('/').concat('/')
      const parent: CustomFileData = {
        id,
        name,
        modDate: file.modDate,
        size: 0,
        children: [file],
        isDir: true,
        shared: false,
        parentId: idParent,
      }
      buildMap(parent, fileMap)
    }
  }
  return fileMap
}

export const useFileMap = (data: dbItem[] | undefined) => {
  const [currentFolderId, setCurrentFolderId] = useState(ROOT_FOLDER)
  const currentFolderIdRef = useRef(currentFolderId)
  useEffect(() => {
    currentFolderIdRef.current = currentFolderId
  }, [currentFolderId])

  const baseFileMap = useMemo(() => populateBaseFileMap(data), [data])
  const [fileMap, setFileMap] = useState(baseFileMap)
  //Fixes fileBrowser empty on first render
  useEffect(() => {
    setFileMap(baseFileMap)
  }, [baseFileMap])

  const filterSharedFiles = useCallback((files: CustomFileData[]) => {
    setFileMap((currentFileMap: CustomFileMap) => {
      const newFileMap = {...currentFileMap}
      var filteredMap: CustomFileMap = {}
      getLogger().info('newFileMap', newFileMap)
      var sharedFilesId: string[] = []
      files
        .filter((file) => file!.shared)
        .forEach((file) => {
          sharedFilesId.push(file.id)
        })
      sharedFilesId.forEach((id) => {
        filteredMap = buildMap(newFileMap[id], filteredMap)
      })
      return filteredMap
    })
  }, [])
  return {fileMap, setFileMap, currentFolderId, setCurrentFolderId, filterSharedFiles}
}

export const exportedForTesting = {
  populateBaseFileMap,
  buildMap,
}
