/* eslint-disable jsx-a11y/anchor-is-valid */
import {CognitoUser} from 'amazon-cognito-identity-js'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../_omicsbox/helpers'
import {getLogger} from '../../../../_omicsbox/helpers/logUtils'
import {toastDefaultConfig} from '../../../../_omicsbox/toastConfig'
import {useCognitoAuth} from '../core/CognitoAuth'
import {cognitoSignUp} from '../core/_requests'
import {signInGoogle} from './Login'

export const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
}

export const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(3, 'Must be at least 8 characters')
    .max(50, 'Maximum 50 symbols')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
      'Password must contain uppercase, lowercase, number and special character'
    )
    .required('Password is required'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const {saveCognitoAuth, setCurrentUser, setConfirmSignUp} = useCognitoAuth()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const userRegistered: CognitoUser = await cognitoSignUp(
          values.email,
          values.firstname,
          values.lastname,
          values.password
        )
        saveCognitoAuth(userRegistered)

        setCurrentUser({
          email: values.email,
          name: `${values.firstname} ${values.lastname}`,
          sub: '',
          identity_id: '',
          email_verified: false,
          updated_at: '',
        })
        setConfirmSignUp(true)
        navigate('/auth/validate-registration')
      } catch (error) {
        saveCognitoAuth(undefined)
        setStatus('The registration details are incorrect')
        setSubmitting(false)
        setLoading(false)
        //@ts-ignore
        toast(error.message, {...toastDefaultConfig, type: 'error'})
        getLogger().error(error)
      }
    },
  })

  return (
    <>
      <div className='container'>
        <div className='modal-dialog-cognito'>
          <div className='modal-content background-customizable modal-content-desktop visible-md visible-lg'>
            <div className='text-center banner-customizable'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/ob_web.png')} className='h-95px' />
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='panel panel-right-border col-md-6 col-lg-6'>
                  <span className='idpDescription-customizable'>
                    Sign In with your social account
                  </span>

                  <button
                    name='googleSignUpButton'
                    onClick={signInGoogle}
                    className='btn google-button socialButton-customizable'
                  >
                    <span>
                      <svg
                        className='social-logo'
                        viewBox='0 0 256 262'
                        xmlns='http://www.w3.org/2000/svg'
                        preserveAspectRatio='xMidYMid'
                      >
                        <path
                          d='M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027'
                          fill='#4285F4'
                        ></path>
                        <path
                          d='M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1'
                          fill='#34A853'
                        ></path>
                        <path
                          d='M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782'
                          fill='#FBBC05'
                        ></path>
                        <path
                          d='M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251'
                          fill='#EA4335'
                        ></path>
                      </svg>
                    </span>
                    <span>Continue with Google</span>
                  </button>

                  <br />
                  <span className='legalText-customizable'>
                    We won't post to any of your accounts without asking first
                  </span>
                </div>

                <div className='login-or col-md-1 col-lg-1'>
                  <hr className='hr-or' />
                  <span className='span-or background-customizable'>or</span>
                </div>

                <div className='panel panel-left-border col-md-6 col-lg-6'>
                  <div className='span-or-verical background-customizable'>or</div>
                  <div>
                    <div>
                      <form
                        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                        noValidate
                        id='kt_login_signup_form'
                        onSubmit={formik.handleSubmit}
                      >
                        <span className='textDescription-customizable '>
                          Sign up with a new account
                        </span>

                        <label className='label-customizable'>Email</label>
                        <div>
                          <input
                            placeholder='Email'
                            type='email'
                            autoComplete='off'
                            {...formik.getFieldProps('email')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid  inputField-customizable',
                              {'is-invalid': formik.touched.email && formik.errors.email},
                              {
                                'is-valid': formik.touched.email && !formik.errors.email,
                              }
                            )}
                          />
                        </div>
                        <div>
                          <label className='label-customizable'>Given name</label>
                          <div>
                            <input
                              placeholder='Given name'
                              type='text'
                              autoComplete='off'
                              {...formik.getFieldProps('firstname')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid inputField-customizable',
                                {
                                  'is-invalid': formik.touched.firstname && formik.errors.firstname,
                                },
                                {
                                  'is-valid': formik.touched.firstname && !formik.errors.firstname,
                                }
                              )}
                              required
                            />
                          </div>
                        </div>
                        <div>
                          <label className='label-customizable'>Family name</label>
                          <div>
                            <input
                              placeholder='Family name'
                              type='text'
                              autoComplete='off'
                              required
                              {...formik.getFieldProps('lastname')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid inputField-customizable',
                                {
                                  'is-invalid': formik.touched.lastname && formik.errors.lastname,
                                },
                                {
                                  'is-valid': formik.touched.lastname && !formik.errors.lastname,
                                }
                              )}
                            />
                          </div>
                        </div>
                        <label className='label-customizable'>Password</label>
                        <br />
                        <input
                          type='password'
                          placeholder='Password'
                          autoComplete='off'
                          {...formik.getFieldProps('password')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid  inputField-customizable',
                            {
                              'is-invalid': formik.touched.password && formik.errors.password,
                            },
                            {
                              'is-valid': formik.touched.password && !formik.errors.password,
                            }
                          )}
                        />
                        <br />

                        <div>
                          <div>
                            {formik.touched.email &&
                              (formik.errors.email ? (
                                <div className='passwordCheck-notValid-customizable'>
                                  <span aria-hidden='true' className='check-symbols'>
                                    ✖
                                  </span>
                                  <span className='checkPasswordText-symbols'>Valid e-mail.</span>
                                </div>
                              ) : (
                                <div className='passwordCheck-valid-customizable'>
                                  <span className='check-lowerletter'>✓</span>
                                  <span className='checkPasswordText-lowerletter'>
                                    Valid e-mail.
                                  </span>
                                </div>
                              ))}

                            {formik.touched.firstname &&
                              (formik.errors.firstname ? (
                                <div className='passwordCheck-notValid-customizable'>
                                  <span aria-hidden='true' className='check-symbols'>
                                    ✖
                                  </span>
                                  <span className='checkPasswordText-symbols'>
                                    Valid given name.
                                  </span>
                                </div>
                              ) : (
                                <div className='passwordCheck-valid-customizable'>
                                  <span className='check-lowerletter'>✓</span>
                                  <span className='checkPasswordText-lowerletter'>
                                    Valid given name.
                                  </span>
                                </div>
                              ))}

                            {formik.touched.lastname &&
                              (formik.errors.lastname ? (
                                <div className='passwordCheck-notValid-customizable'>
                                  <span aria-hidden='true' className='check-symbols'>
                                    ✖
                                  </span>
                                  <span className='checkPasswordText-symbols'>
                                    Valid family name.
                                  </span>
                                </div>
                              ) : (
                                <div className='passwordCheck-valid-customizable'>
                                  <span className='check-lowerletter'>✓</span>
                                  <span className='checkPasswordText-lowerletter'>
                                    Valid family name.
                                  </span>
                                </div>
                              ))}

                            {formik.touched.password &&
                              (formik.errors.password ? (
                                <div className='passwordCheck-notValid-customizable'>
                                  <span aria-hidden='true' className='check-symbols'>
                                    ✖
                                  </span>
                                  <span className='checkPasswordText-symbols'>
                                    Password must contain a lower/upper case letter, a number, a
                                    special character and be at least 8 characters long.
                                  </span>
                                </div>
                              ) : (
                                <div className='passwordCheck-valid-customizable'>
                                  <span className='check-lowerletter'>✓</span>
                                  <span className='checkPasswordText-lowerletter'>
                                    Password must contain a lower/upper case letter, a number, a
                                    special character and be at least 8 characters long.
                                  </span>
                                </div>
                              ))}
                          </div>
                        </div>

                        <button
                          type='submit'
                          id='kt_sign_up_submit'
                          className='btn btn-primary signUpButton submitButton-customizable'
                          disabled={loading || formik.isSubmitting || !formik.isValid}
                        >
                          Sign up
                        </button>
                        <br />
                        <p className='redirect-customizable'>
                          <span>Already have an account?</span>&nbsp;
                          <Link to='/auth/login'>Sign in</Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
