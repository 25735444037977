import {getLogger} from '@/_omicsbox/helpers'
import {Auth} from 'aws-amplify'
import {CognitoUserSession} from 'amazon-cognito-identity-js'

const getUserIdentityId = (): string | undefined => {
  const user = localStorage.getItem('cognito-user')
  if (user) {
    const userObj = JSON.parse(user)
    return userObj.attributes['custom:identityId']
  }
}

const refreshToken = async () => {
  getLogger().info('Refresh token')
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    const currentSession = await Auth.currentSession()

    cognitoUser.refreshSession(
      currentSession.getRefreshToken(),
      (err: any, session: CognitoUserSession) => {
        if (err) {
          getLogger().error(`Refresh token failed due to: ${err.message}`)
        } else {
          const currentCognitoUser = localStorage.getItem('cognito-user')
          if (currentCognitoUser) {
            localStorage.setItem(
              'cognito-user',
              JSON.stringify({
                ...JSON.parse(currentCognitoUser),
                signInUserSession: {
                  clockDrift: 1,
                  idToken: session.getIdToken(),
                  refreshToken: session.getRefreshToken(),
                  accessToken: session.getAccessToken(),
                },
              })
            )
          }
        }
      }
    )
  } catch (error: any) {
    getLogger().error(`Refresh token failed due to: ${error.message}`)
  }
}

export {getUserIdentityId, refreshToken}
