import {CustomFileData, findIds, getLogger, wsfileActionMessage} from '@/_omicsbox/helpers'
import {toastDefaultConfig, toastInfiniteConfig} from '@/_omicsbox/toastConfig'
import {useQueryClient} from '@tanstack/react-query'
import {saveAs} from 'file-saver'
import JSZip from 'jszip'
import {useRef, useState} from 'react'
import {Id, toast} from 'react-toastify'
import {useCognitoAuth} from '../../auth'
import {copyFiles, downloadFile, moveFiles, shareFiles, unshareFiles} from '../core/_requests'
import {useSocket} from './SocketProvider'

const useFileTable = (selectedFiles: CustomFileData[], path: string[] = ['']) => {
  const {socket, updateInfiniteToast} = useSocket([], [])
  const zip = new JSZip()
  const queryClient = useQueryClient()
  const {currentUser} = useCognitoAuth()
  const identityId = currentUser?.identity_id || ''
  const folderPath = useRef<string[]>(path)
  const [renamedFile, setRenamedFile] = useState<string>('')
  const filesToCopy = useRef<CustomFileData[]>([])
  const filesToMove = useRef<CustomFileData[]>([])
  const onCopy = () => {
    if (selectedFiles.length === 0) {
      toast('No files selected', {...toastDefaultConfig, type: 'warning'})
      return
    }
    filesToMove.current = []
    filesToCopy.current = selectedFiles
  }
  const onCut = () => {
    if (selectedFiles.length === 0) {
      toast('No files selected', {...toastDefaultConfig, type: 'warning'})
      return
    }

    filesToCopy.current = []
    filesToMove.current = selectedFiles
  }
  function sendWsMessage(
    action: string,
    destination: string | undefined,
    fileCount: string,
    customAttributes: any
  ) {
    const socketMessage: wsfileActionMessage = {
      source: 'client.omicsbox.web',
      message: {action, filePath: destination, ...customAttributes},
      fileCount,
    }
    getLogger().info('message sent', socketMessage)
    socket?.send(JSON.stringify(socketMessage))
  }

  const onPaste = (folderPath: string[]) => {
    const destination = identityId + folderPath.join('/') + '/'
    let source: string[] = []
    if (filesToCopy.current.length > 0) {
      source = filesToCopy.current.map((file) => file.id)
      copyFiles({source, destination}, identityId)
        ?.then(() => {
          updateInfiniteToast(toast.loading('Copying files...', toastInfiniteConfig))
        })
        .catch((err: any) => getLogger().error(err))
      sendWsMessage(
        'copy',
        destination,
        countFiles(filesToCopy.current).toLocaleString(),
        undefined
      )
    } else if (filesToMove.current.length > 0) {
      source = filesToMove.current.map((file) => file.id)
      moveFiles({source, destination}, identityId)
        ?.then(() => {
          updateInfiniteToast(toast.loading('Moving files...', toastInfiniteConfig))
        })
        .catch((err: any) => getLogger().error(err))
      sendWsMessage(
        'move',
        destination,
        countFiles(filesToMove.current).toLocaleString(),
        undefined
      )
    }
    filesToCopy.current = []
    filesToMove.current = []
  }
  function countFiles(fileMap: CustomFileData[]): number {
    let fileIds = findIds(fileMap)
    let folders: string[] = []
    let files: string[] = []
    fileIds.forEach((file) => (file.endsWith('/') ? folders.push(file) : files.push(file)))
    let dynamoFiles: string[] = folders.filter(
      (folder) => !files.some((file) => file.startsWith(folder))
    )

    return files.length + dynamoFiles.length
  }
  const onShare = async () => {
    await shareFiles(
      selectedFiles.map((file) => file.id),
      identityId
    )
    queryClient.invalidateQueries(['files'])
  }

  const onDownload = async () => {
    const fileNames = findIds(selectedFiles).filter((id) => !id.endsWith('/'))
    let t: Id | undefined = undefined
    const filesDownloaded = await Promise.all(
      fileNames.map((file) => downloadFile(file, fileNames.length > 1))
    ).then((result) => {
      toast('Files downloaded!', {...toastDefaultConfig, type: toast.TYPE.SUCCESS})
      return result
    })
    if (typeof filesDownloaded[0] === 'string') {
      window.open(filesDownloaded[0])
    } else {
      for (let index = 0; index < fileNames.length; index++) {
        const fileName = fileNames[index]
        const content = filesDownloaded[index]
        zip.file(fileName, content)
      }
      zip
        .generateAsync({type: 'blob'}, (p_1) => {
          const progress = p_1.percent / 100
          if (t === undefined) {
            t = toast('Compressing...', {
              ...toastDefaultConfig,
              hideProgressBar: false,
              closeOnClick: false,
              closeButton: false,
              progress,
            })
          } else {
            toast.update(t, {
              progress,
            })
          }
        })
        .then((blob) => {
          setTimeout(() => {
            if (t) {
              toast.done(t)
            }
            toast.dismiss()
            toast.clearWaitingQueue()
          }, 100)

          saveAs(
            blob,
            `omicsbox-download-${new Date().toISOString().substring(0, 19).replace(/[-:]/g, '')}`
          )
        })
        .catch((error) => getLogger().error(error))
    }
  }

  const onUnshare = () => {
    const filePaths = selectedFiles.map((file) => file.id)

    unshareFiles(filePaths, identityId)!
      .then((unshareResponse) => {
        toast(`Objects unshared: ${unshareResponse.objectsUnshared.join(', ')}`, toastDefaultConfig)
        queryClient.invalidateQueries(['files'])
      })
      .catch((e) => getLogger().error(e))
  }

  return {
    onCopy,
    onCut,
    filesToCopy,
    filesToMove,
    onPaste,
    onShare,
    onDownload,
    renamedFile,
    setRenamedFile,
    onUnshare,
    folderPath,
    countFiles,
    sendWsMessage,
  }
}

export default useFileTable
