import {FC} from 'react'
import {ErrorBoundary, FallbackProps} from 'react-error-boundary'
// import {BridgeViewerManager} from '../modules/widgets/components/external-viewer/bridge-viewer'
import ViewerManager from '../modules/widgets/components/external-viewer/viewer-manager'

function ErrorFallback({error, resetErrorBoundary}: FallbackProps) {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}
function refreshPage() {
  window.location.reload()
}
export const ViewerPart: FC = () => {
  return (
    <div className='card-xl-stretch mb-xl-8'>
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={refreshPage}>
        <ViewerManager title='Opened Files' />
      </ErrorBoundary>
    </div>
  )
}

export default ViewerPart
