import {CognitoUser} from 'amazon-cognito-identity-js'
import {Auth} from 'aws-amplify'
import axios from 'axios'
import {UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const AUTH_USER_TOKEN_KEY = `JWT-TOKEN`

// Server should return CognitoUser
// https://github.com/aws-amplify/amplify-js/blob/4644b4322ee260165dd756ca9faeb235445000e3/packages/amazon-cognito-identity-js/index.d.ts#L48
export async function cognitologin(email: string, password: string): Promise<CognitoUser> {
  var cognitoUser: CognitoUser = await Auth.signIn(email, password, {})
  localStorage.setItem('cognito-user', JSON.stringify(cognitoUser))
  return cognitoUser
}

export async function cognitoSignUp(
  email: string,
  firstname: string,
  lastname: string,
  password: string
): Promise<CognitoUser> {
  const {user} = await Auth.signUp({
    username: email,
    password,
    attributes: {
      email,
      given_name: firstname,
      family_name: lastname,
    },
  })
  return user
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string): Promise<any> {
  return Auth.forgotPassword(email, {})
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
