import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {Amplify} from 'aws-amplify'
import {Chart, registerables} from 'chart.js'
import {createRoot} from 'react-dom/client'
import {ToastContainer} from 'react-toastify'
import {CognitoAuthProvider} from './app/modules/auth'
import {AppRoutes} from './app/routing/AppRoutes'
import {awsConfig} from './aws-config'
import {worker} from './mocks/browser'
import './_omicsbox/assets/sass/style.react.scss'
import './_omicsbox/assets/sass/style.scss'

import {I18nProvider} from './_omicsbox/i18n/i18n'

if (process.env.REACT_APP_ENV === 'TEST') worker.start()
Amplify.configure(awsConfig)

Chart.register(...registerables)

const queryClient = new QueryClient({defaultOptions: {queries: {refetchOnWindowFocus: false}}})
const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <QueryClientProvider client={queryClient}>
    <ToastContainer limit={5} />
    <I18nProvider>
      <CognitoAuthProvider>
        <AppRoutes />
      </CognitoAuthProvider>
    </I18nProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)
