import {ToastOptions} from 'react-toastify'

export const toastDefaultConfig: ToastOptions = {
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: false,
  draggable: false,
  progressStyle: {background: '#888E00'},
}

export const toastInfiniteConfig: ToastOptions = {
  position: 'bottom-right',
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: false,
  rtl: false,
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
}
