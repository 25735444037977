import {CSSProperties, useRef} from 'react'
interface dropzoneProps {
  children: JSX.Element
  onDrop: (fileList: FileList) => void
  style?: CSSProperties
}

export const DropZone = ({children, onDrop, style}: dropzoneProps) => {
  const dragZone = useRef<HTMLDivElement>(null)
  var counter = 0

  return (
    <div
      style={style}
      onDragEnter={(e) => {
        e.preventDefault()
        counter++
        if (dragZone.current) {
          dragZone.current.classList.add('dragging')
        }
      }}
      onDragOver={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
      onDragLeave={(e) => {
        if (e.currentTarget.id === 'drop_zone') {
          e.preventDefault()
          if (dragZone.current) {
            counter--
            if (counter === 0) dragZone.current.classList.remove('dragging')
          }
        }
      }}
      onDrop={(e) => {
        e.preventDefault()
        if (dragZone.current) {
          dragZone.current.classList.remove('dragging')
          onDrop(e.dataTransfer.files)
          counter = 0
        }
      }}
      id='drop_zone'
      ref={dragZone}
    >
      {children}
    </div>
  )
}
