import moment from 'moment'
import {CustomFileData} from './crud-helper/models'

export const copylinksToClipboard = (filesToCopy: string[]): void => {
  const copyText = filesToCopy.join(',\n')
  navigator.clipboard.writeText(copyText)
}

export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function findIds(files: CustomFileData[]) {
  const fileNames: string[] = []
  JSON.stringify(files, (key, value) => {
    if (key === 'id') fileNames.push(value)
    return value
  })
  return fileNames
}

export function convertTimestampToDateString(timestamp: number) {
  return moment(timestamp).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
}

export function removeBasePath(path: string) {
  return path.substring(path.indexOf('/') + 1)
}
