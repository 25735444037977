import {StorageGetOutput} from '@aws-amplify/storage'
import {CustomFileData, CustomFileMap, dbItem, JobHistory} from '../../_omicsbox/helpers'

const mockCurrentUser = {
  sub: '1234-5678-lsakdfj',
  email: 'lmiret@biobam.com',
  email_verified: true,
  name: 'lorenzo',
  updated_at: '2020-04-01T00:00:00.000Z',
  identity_id: 'asdc1234-adsd',
}

const mockSelectedFiles: CustomFileData[] = [
  {
    id: 'namaste.jpg',
    name: 'namaste.jpg',
    modDate: '2022-12-22T09:00:47.296Z',
    size: 96413,
    isDir: false,
    children: [],
    parentId: '',
    shared: true,
    shareId: '1234',
  },
  {
    id: 'counts_mice.box',
    name: 'counts_mice.box',
    modDate: '2022-11-30T08:54:31.224Z',
    size: 2736721,
    isDir: false,
    children: [],
    parentId: '',
    shared: true,
    shareId: '4456',
  },
]

const mockUseFetchData: dbItem[] = [
  {
    size: 0,
    file: 'zxczxczxcv/',
    prefix: 'us-east-2:0d24e17f-23cb-41a9-9f44-6e3ee80a134f',
    eTag: 'd41d8cd98f00b204e9800998ecf8427e',
    eventTime: '2022-12-13T10:19:50.807Z',
    keyEncoded: 'us-east-2%3A0d24e17f-23cb-41a9-9f44-6e3ee80a134f/zxczxczxcv/',
  },
  {
    size: 389190,
    file: 'zxczxczxcv/GraphQLvsREST.png',
    prefix: 'us-east-2:0d24e17f-23cb-41a9-9f44-6e3ee80a134f',
    eTag: 'b7b36404fc501f1971a947bce110fcf3',
    eventTime: '2022-12-15T08:38:43.850Z',
    keyEncoded: 'us-east-2%3A0d24e17f-23cb-41a9-9f44-6e3ee80a134f/zxczxczxcv/GraphQLvsREST.png',
  },
  {
    size: 11140,
    file: '0tinyjoint.webp',
    prefix: 'us-east-2:0d24e17f-23cb-41a9-9f44-6e3ee80a134f',
    eTag: '60c056bb78085a4c95e963a804e82bab',
    eventTime: '2022-11-29T14:53:33.695Z',
    keyEncoded: 'us-east-2%3A0d24e17f-23cb-41a9-9f44-6e3ee80a134f/0tinyjoint.webp',
  },
  {
    size: 982,
    file: 'we/omicsbox_icon.svg',
    prefix: 'us-east-2:0d24e17f-23cb-41a9-9f44-6e3ee80a134f',
    eTag: '00b53ac8c7fa526af6144daa1a6f75a3',
    eventTime: '2022-11-22T10:37:51.935Z',
    keyEncoded: 'us-east-2%3A0d24e17f-23cb-41a9-9f44-6e3ee80a134f/we/omicsbox_icon.svg',
  },
  {
    size: 0,
    file: 'we/',
    prefix: 'us-east-2:0d24e17f-23cb-41a9-9f44-6e3ee80a134f',
    eTag: 'd41d8cd98f00b204e9800998ecf8427e',
    eventTime: '2022-11-22T10:55:28.205Z',
    keyEncoded: 'us-east-2%3A0d24e17f-23cb-41a9-9f44-6e3ee80a134f/we/',
  },
]

const mockFileData: CustomFileData = {
  id: 'test',
  name: 'test',
  modDate: '',
  size: 0,
  isDir: true,
  childrenCount: 0,
  childrenIds: [],
  parentId: 'root',
  filePath: 'test/',
}

const mockFileMap: CustomFileMap = {
  test: {
    childrenCount: 1,
    childrenIds: ['test/carlos.pdf'],
    filePath: 'test/',
    id: 'test',
    isDir: true,
    modDate: '2022-07-18T13:35:10.529Z',
    name: 'test',
    parentId: 'root',
    size: 0,
  },
  root: {
    childrenCount: 6,
    childrenIds: [
      'test',
      'omicsbox_icon.svg',
      'omicsbox_logo.svg',
      'omicsbox_icon (1).svg',
      'ob_logo_1_print_Icon.svg',
      'ob_logo_1_print_Logo.svg',
    ],
    id: 'root',
    isDir: true,
    modDate: '2022-07-18T13:35:10.529Z',
    name: 'root',
    size: 0,
  },
  '': {
    childrenCount: 1,
    childrenIds: ['root'],
    id: '',
    isDir: true,
    modDate: '2022-07-18T13:35:10.529Z',
    name: '',
    size: 0,
  },
  'test/carlos.pdf': {
    childrenCount: 0,
    childrenIds: [],
    filePath: 'test/carlos.pdf',
    id: 'test/carlos.pdf',
    isDir: false,
    modDate: '2022-07-18T14:07:56.355Z',
    name: 'carlos.pdf',
    parentId: 'test',
    size: 105095,
  },
  'omicsbox_logo.svg': {
    childrenCount: 0,
    childrenIds: [],
    filePath: 'omicsbox_logo.svg',
    id: 'omicsbox_logo.svg',
    isDir: false,
    modDate: '2022-07-18T13:35:50.537Z',
    name: 'omicsbox_logo.svg',
    parentId: 'root',
    size: 1474,
  },
  'omicsbox_icon.svg': {
    childrenCount: 0,
    childrenIds: [],
    filePath: 'omicsbox_icon.svg',
    id: 'omicsbox_icon.svg',
    isDir: false,
    modDate: '2022-07-18T13:35:52.020Z',
    name: 'omicsbox_icon.svg',
    parentId: 'root',
    size: 975,
  },
  'omicsbox_icon (1).svg': {
    childrenCount: 0,
    childrenIds: [],
    filePath: 'omicsbox_icon (1).svg',
    id: 'omicsbox_icon (1).svg',
    isDir: false,
    modDate: '2022-07-18T13:35:49.276Z',
    name: 'omicsbox_icon (1).svg',
    parentId: 'root',
    size: 975,
  },
  'ob_logo_1_print_Logo.svg': {
    childrenCount: 0,
    childrenIds: [],
    filePath: 'ob_logo_1_print_Logo.svg',
    id: 'ob_logo_1_print_Logo.svg',
    isDir: false,
    modDate: '2022-07-18T13:35:47.935Z',
    name: 'ob_logo_1_print_Logo.svg',
    parentId: 'root',
    size: 5867,
  },
  'ob_logo_1_print_Icon.svg': {
    childrenCount: 0,
    childrenIds: [],
    filePath: 'ob_logo_1_print_Icon.svg',
    id: 'ob_logo_1_print_Icon.svg',
    isDir: false,
    modDate: '2022-07-18T13:35:47.077Z',
    name: 'ob_logo_1_print_Icon.svg',
    parentId: 'root',
    size: 864,
  },
}

const mockFile: File = new File([''], 'test.txt', {type: 'text/plain'})

const mockJobHistory: JobHistory = {
  items: [
    {
      countJobsStart: 1,
      serviceName: 'Time Course Expression Analysis',
      isBillable: false,
      SessionId: 'a442ac2c-b8ee-4b3a-a876-92050d60d3b6',
      countJobsDone: 1,
      countJobsCancel: 0,
      lastStopTime: 1662629092235,
      serviceVersion: '1',
      // "email": "cmartinez@biobam.com",
      UserId: 'BOX-MARTCARL-B41B',
      consumedUnits: 32,
      parameters:
        '{\n  "rsq": "0.7",\n  "clusterMethod": "hclust",\n  "file": "count_6222138622244974748.tmp",\n  "designType": "Multi",\n  "design": "design_7018888836076845218.tmp",\n  "cpmFilter": "0.0",\n  "length": "null",\n  "alfa": "0.05",\n  "k": "9",\n  "samplesFilter": "1",\n  "normMethod": "TMM"\n}',
      serviceId: 'masigpro',
      // "username": "47fa39f8-eb32-471f-ab0f-674c2e45e9b4",
      CreationTime: 1662628942232,
      countJobsError: 0,
      billedUnits: '0',
    },
    {
      countJobsStart: 1,
      serviceName: 'Recharge',
      isBillable: true,
      SessionId: 'cd6acd87-38f3-4ec5-bf9a-55e5bb41549f',
      countJobsDone: 1,
      countJobsCancel: 0,
      lastStopTime: 1635859321543,
      serviceVersion: '1',
      UserId: 'BOX-MIRELORE-B8DB',
      consumedUnits: 0,
      parameters: '{}',
      serviceId: 'recharge',
      CreationTime: 1635859321543,
      countJobsError: 0,
      billedUnits: '60000',
    },
  ],
}
async function imgBuffer() {
  var response = await (await fetch('https://picsum.photos/200')).blob()
  return response
}
const mockDownloadFile: StorageGetOutput<any> = {
  $metadata: {
    httpStatusCode: 200,
    requestId: undefined,
    extendedRequestId:
      'qxLDr0+TBfNMtw2k8CZJtNS08r4ST77olSXrHnlzdZzlYwBtd3J9fQhoDnL9TwVdG7uKx/XPbyPGUbROk0fxKA==',
    cfId: undefined,
    attempts: 1,
    totalRetryDelay: 0,
  },
  AcceptRanges: undefined,
  Body: imgBuffer(),
  BucketKeyEnabled: undefined,
  CacheControl: undefined,
  ContentDisposition: undefined,
  ContentEncoding: undefined,
  ContentLanguage: undefined,
  ContentLength: 33038,
  ContentRange: undefined,
  ContentType: 'image/png',
  DeleteMarker: undefined,
  ETag: '"56c2c9888847a893b0cb3a893459ddfe"',
  Expiration: undefined,
  Expires: undefined,
  LastModified: '2023-01-26T12:20:34.000Z',
  Metadata: {},
  MissingMeta: undefined,
  ObjectLockLegalHoldStatus: undefined,
  ObjectLockMode: undefined,
  ObjectLockRetainUntilDate: undefined,
  PartsCount: undefined,
  ReplicationStatus: undefined,
  RequestCharged: undefined,
  Restore: undefined,
  SSECustomerAlgorithm: undefined,
  SSECustomerKeyMD5: undefined,
  SSEKMSKeyId: undefined,
  ServerSideEncryption: 'AES256',
  StorageClass: undefined,
  TagCount: undefined,
  VersionId: undefined,
  WebsiteRedirectLocation: undefined,
}
export const dummyData = {
  mockCurrentUser,
  mockUseFetchData,
  mockFileData,
  mockSelectedFiles,
  mockFileMap,
  mockFile,
  mockJobHistory,
  mockDownloadFile,
}
