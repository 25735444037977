import {toAbsoluteUrl} from '@/_omicsbox/helpers'
import {useCognitoAuth} from '../core/CognitoAuth'
import {Auth} from 'aws-amplify'

import {toast} from 'react-toastify'
import {getLogger} from '../../../../_omicsbox/helpers/logUtils'
import {toastDefaultConfig} from '../../../../_omicsbox/toastConfig'
import VerificationCodeInput, {ofuscateEmail} from './VerificationCodeInput'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import clsx from 'clsx'
import {useState} from 'react'

export const ValidateRegistration = () => {
  const {verificationCode, currentUser, setCurrentUser} = useCognitoAuth()
  const [loading, setLoading] = useState(false)
  const [verificationCodeComplete, setVerificationCodeComplete] = useState(false)
  const navigate = useNavigate()

  const navigateTo = (path: string) => {
    setTimeout(() => navigate(path), 1000)
  }
  const initialValues = {
    code: '',
  }

  const registrationSchema = Yup.object().shape({
    code: Yup.string()
      .length(6, 'Must be composed of 6 numbers')
      .required('Verification code is required')
      .matches(/[0-9]{6}/, 'Must be composed of 6 numbers'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      // call to forgotPasswordSubmit
      try {
        if (!currentUser) {
          throw new Error('User information missing!')
        }
        if (!currentUser.email) {
          throw new Error('User email missing!')
        }
        if (!verificationCode) {
          throw new Error('No verification code')
        }
        await Auth.confirmSignUp(currentUser.email, verificationCode)

        currentUser.email_verified = true
        setCurrentUser(currentUser)
        setLoading(false)
        navigateTo('/auth/login')
      } catch (error) {
        //@ts-ignore
        toast(error.message, {...toastDefaultConfig, type: 'error'})
        getLogger().error(error)

        setLoading(false)
      }
    },
  })

  const codeCompleted = (code: string) => {
    // We must need to wait until the code has been properly filled and set in the children component
    setVerificationCodeComplete(true)
  }

  return (
    <>
      <div className='container'>
        <div className='modal-dialog-cognito'>
          <div className='modal-content background-customizable modal-content-mobile'>
            <div className='text-center banner-customizable'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/ob_web.png')} className='h-95px' />
            </div>
            <div className='modal-body'>
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_registration_form_validation'
                onSubmit={formik.handleSubmit}
              >
                <br />
                <div id='div-forgot-password-msg' className='mb-5'>
                  <span id='text-code'>
                    We have sent a validation code by email to{' '}
                    {currentUser?.email ? ofuscateEmail(currentUser.email) : '<not defined>'} Enter
                    it below to complete your registration.
                  </span>
                </div>
                <label htmlFor='validate_registration'>Code</label>

                <VerificationCodeInput
                  {...formik.getFieldProps('code')}
                  onComplete={codeCompleted}
                  className={clsx(
                    'form-control form-control-lg form-control-solid  inputField-customizable',
                    {
                      'is-invalid': formik.touched.code && formik.errors.code,
                    },
                    {
                      'is-valid': formik.touched.code && !formik.errors.code,
                    }
                  )}
                />

                <div>
                  <div></div>
                </div>

                <button
                  name='complete_register'
                  type='submit'
                  className='btn btn-primary submitButton-customizable'
                  disabled={
                    !verificationCodeComplete || loading || formik.isSubmitting || !formik.isValid
                  }
                >
                  Validate registration
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
