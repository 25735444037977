import {toAbsoluteUrl} from '@/_omicsbox/helpers'
import {Auth} from 'aws-amplify'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {getLogger} from '../../../../_omicsbox/helpers/logUtils'
import {toastDefaultConfig} from '../../../../_omicsbox/toastConfig'
import {useCognitoAuth} from '../core/CognitoAuth'
import VerificationCodeInput, {ofuscateEmail} from './VerificationCodeInput'

const initialValues = {
  code: '',
  password: '',
  changepassword: '',
}

const resetSchema = Yup.object().shape({
  code: Yup.string()
    .length(6, 'Must be composed of 6 numbers')
    .required('Verification code is required')
    .matches(/[0-9]{6}/, 'Must be composed of 6 numbers'),
  password: Yup.string()
    .min(3, 'Must be at least 8 characters')
    .max(50, 'Maximum 50 symbols')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
      'Password must contain uppercase, lowercase, number and special character'
    )
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
})

export const ValidateForgotPassword = () => {
  const [loading, setLoading] = useState(false)
  const [verificationCodeComplete, setVerificationCodeComplete] = useState(false)
  const {verificationCode, currentUser} = useCognitoAuth()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: resetSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      // call to forgotPasswordSubmit
      try {
        if (!verificationCode) {
          throw new Error('No verification code')
        }
        if (!currentUser) {
          throw new Error('User information missing!')
        }
        if (!currentUser.email) {
          throw new Error('email missing!')
        }
        const value = await Auth.forgotPasswordSubmit(
          currentUser.email,
          verificationCode,
          values.password
        )
        getLogger().log(value)
        navigate('/auth/login')
      } catch (e) {
        setStatus(e)
        setSubmitting(false)
        setLoading(false)
        // @ts-ignore
        toast(e.message, {...toastDefaultConfig, type: 'error'})
        getLogger().error(e)
      }
    },
  })

  const codeCompleted = (code: string) => {
    // We must need to wait until the code has been properly filled and set in the children component
    setVerificationCodeComplete(true)
  }

  return (
    <>
      <div className='container'>
        <div className='modal-dialog-cognito'>
          <div className='modal-content background-customizable modal-content-mobile'>
            <div className='text-center banner-customizable'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/ob_web.png')} className='h-95px' />
            </div>
            <div className='modal-body'>
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form_validation'
                onSubmit={formik.handleSubmit}
              >
                <br />
                <div id='div-forgot-password-msg' className='mb-5'>
                  <span id='text-code'>
                    We have sent a password reset code by email to{' '}
                    {currentUser?.email ? ofuscateEmail(currentUser.email) : '<not defined>'} Enter
                    it below to reset your password.
                  </span>
                </div>
                <label htmlFor='forgot_password_code'>Code</label>

                <VerificationCodeInput
                  {...formik.getFieldProps('code')}
                  onComplete={codeCompleted}
                  className={clsx(
                    'form-control form-control-lg form-control-solid  inputField-customizable',
                    {
                      'is-invalid': formik.touched.code && formik.errors.code,
                    },
                    {
                      'is-valid': formik.touched.code && !formik.errors.code,
                    }
                  )}
                />

                <label htmlFor='new_password'>New Password</label>
                <input
                  type='password'
                  placeholder='Password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid  inputField-customizable',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                <label htmlFor='confirm_password'>Enter New Password Again</label>
                <input
                  type='password'
                  placeholder='Confirm Password'
                  autoComplete='off'
                  {...formik.getFieldProps('changepassword')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid  inputField-customizable',
                    {
                      'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                    },
                    {
                      'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                    }
                  )}
                />
                <div>
                  <div>
                    {formik.touched.password &&
                      (formik.errors.password ? (
                        <div className='passwordCheck-notValid-customizable'>
                          <span aria-hidden='true' className='check-symbols'>
                            ✖
                          </span>
                          <span className='checkPasswordText-symbols'>
                            Password must contain a lower/upper case letter, a number, a special
                            character and be at least 8 characters long.
                          </span>
                        </div>
                      ) : (
                        <div className='passwordCheck-valid-customizable'>
                          <span className='check-lowerletter'>✓</span>
                          <span className='checkPasswordText-lowerletter'>
                            Password must contain a lower/upper case letter, a number, a special
                            character and be at least 8 characters long.
                          </span>
                        </div>
                      ))}

                    {formik.touched.changepassword &&
                      (formik.errors.changepassword ? (
                        <div className='passwordCheck-notValid-customizable'>
                          <span aria-hidden='true' className='check-symbols'>
                            ✖
                          </span>
                          <span className='checkPasswordText-symbols'>Passwords must match.</span>
                        </div>
                      ) : (
                        <div className='passwordCheck-valid-customizable'>
                          <span className='check-lowerletter'>✓</span>
                          <span className='checkPasswordText-lowerletter'>
                            Passwords must match.
                          </span>
                        </div>
                      ))}
                  </div>
                </div>

                <button
                  name='reset_password'
                  type='submit'
                  className='btn btn-primary submitButton-customizable'
                  disabled={
                    !verificationCodeComplete || loading || formik.isSubmitting || !formik.isValid
                  }
                >
                  Change Password
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
