import log from 'loglevel'
import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import {LayoutProvider, LayoutSplashScreen} from '../_omicsbox/layout/core'
import {MasterInit} from '../_omicsbox/layout/MasterInit'
import {CognitoAuthInit} from './modules/auth'

export function getLogger() {
  var level = process.env.ENV
  log.setLevel(level === 'PROD' ? log.levels.ERROR : log.levels.DEBUG)
  return log
}

const App = () => {
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <LayoutProvider>
          <CognitoAuthInit>
            <Outlet />
            <MasterInit />
          </CognitoAuthInit>
        </LayoutProvider>
      </Suspense>
    </>
  )
}

export {App}
