import {CustomSVG} from './components/CustomSVG'
import {CustomFileData} from './crud-helper/models'

interface props {
  customFile: CustomFileData
}

export const FileIcon: React.FC<props> = ({customFile}) => {
  const folder = 'fas fa-folder'
  const image = 'fas fa-file-image'
  const file = 'fas fa-file-alt'
  const zip = 'fas fa-file-archive'
  let icon: string = file
  let fileExt = customFile.name.substring(customFile.name.lastIndexOf('.') + 1)
  let isSvg =
    fileExt === 'gif' ||
    fileExt === 'jpg' ||
    fileExt === 'pdf' ||
    fileExt === 'png' ||
    fileExt === 'tif' ||
    fileExt === 'txt' ||
    fileExt === 'zip' ||
    fileExt === 'svg' ||
    fileExt === 'box'

  if (customFile.isDir) {
    icon = folder
  } else {
    if (fileExt !== '') {
      if (fileExt === 'jpeg' || fileExt === 'tiff') {
        icon = image
      } else if (fileExt === '7z' || fileExt === 'gz' || fileExt === 'rar') {
        icon = zip
      }
    }
  }

  if (isSvg) {
    return (
      <>
        <CustomSVG path={`/media/svg/file-extension-logos/${fileExt}.svg`} className='svg-icon-1' />
      </>
    )
  } else {
    return (
      <>
        <i className={`${icon}`} style={{marginLeft: '2px', fontSize: '1.6rem'}}></i>
      </>
    )
  }
}
