import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingFn,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import moment from 'moment'

import { uploadFolder } from '@/_omicsbox/helpers/FileHelpers'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  createContext,
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useContextMenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { toast } from 'react-toastify'
import {
  convertTimestampToDateString,
  copylinksToClipboard,
  CustomFileData,
  dbItem,
  findIds,
  formatBytes,
  getLogger,
  RenameProps,
  useFetchFiles,
  useFileMap,
} from '@/_omicsbox/helpers'
import { FileIcon } from '@/_omicsbox/helpers/FileIcon'
import { toastDefaultConfig, toastInfiniteConfig } from '@/_omicsbox/toastConfig'
import { useCognitoAuth } from '../../auth'
import { downloadFile, removeFile, renameFile, uploadFile, getFileS3Url } from '../core/_requests'
import { CustomModal } from './CustomModal'
import { DropZone } from './DropZone'
import { collapseProps, collapsibleCard } from './external-viewer/collapsible-card'
import { FileTableHeader } from './FileTableHeader'
import { FileTableMenu, MENU_ID } from './FileTableMenu'
import { LoadingSpinner } from './LoadingSpinner'
import useFileTable from './useFileTable'
import { useSocket } from './SocketProvider'
import { createRequestViewerMessage, REST_SERVER, REST_ACTIONS } from './external-viewer/handler-structures'
declare module '@tanstack/table-core' {
  interface SortingFns {
    foldersFirstSort?: SortingFn<unknown>
  }
}

const ROOT_FOLDER = ''

//React table configuration
const columnHelper = createColumnHelper<CustomFileData>()
const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: ({ row, getValue }) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          cursor: 'default',
          userSelect: 'none',
        }}
      >
        <FileIcon customFile={row.original} />
        <div style={{ paddingLeft: '5px' }}>{getValue()}</div>
      </div>
    ),
    sortingFn: 'foldersFirstSort',
  }),
  columnHelper.accessor('modDate', {
    cell: (info) =>
      info.getValue() ? (
        <div style={{ cursor: 'default', userSelect: 'none' }}>
          {moment(info.getValue()).format('DD/MM/yyyy HH:mm:ss')}
        </div>
      ) : null,
    header: 'Last modified',
  }),
  columnHelper.accessor('size', {
    cell: (info) =>
      info.getValue() ? (
        <div style={{ cursor: 'default', userSelect: 'none' }}>
          {formatBytes(info.getValue()!, 0)}
        </div>
      ) : null,
    header: 'Size',
  }),
  columnHelper.accessor('shared', {
    cell: (info) =>
      info.getValue() ? (
        <div className='d-flex justify-content-center'>
          <i
            className='fas fa-link'
            style={{ cursor: 'pointer', textAlign: 'center', userSelect: 'none' }}
            title='Copy link'
            onClick={(e) => {
              e.stopPropagation()
              copylinksToClipboard([info.row.original.id])
            }}
          ></i>
        </div>
      ) : null,
    header: () => <div className='d-flex justify-content-center'>Shared</div>,
  }),
]

export const FileTableContext = createContext({
  showRenameModal: false,
  setShowRenameModal: (show: boolean) => { },
  showDeleteConfirmation: false,
  setShowDeleteConfirmation: (show: boolean) => { },
  resetSelection: (row?: Row<CustomFileData>) => { },
  selectedFiles: () => [] as CustomFileData[],
  onReturn: () => { },
  onNewFolder: () => { },
  onBreadCrumb: (e: SyntheticEvent) => { },
  onUploadFile: () => { },
  onUploadFolder: () => { },
  folderPath: [] as string[],
  folderPathRef: [] as unknown as React.MutableRefObject<string[]>,
  fileToRename: {} as CustomFileData | undefined,
  setFileToRename: (file: CustomFileData) => { },
  onRename: () => { },
})

function FileTable<T extends collapseProps>(props: T) {
  const queryClient = useQueryClient()
  const { currentUser } = useCognitoAuth()
  const identityId = useRef<string>(currentUser?.identity_id || '')
  const folderPathRef = useRef<string[]>([ROOT_FOLDER])
  const {
    data: fileData,
    isError,
    error,
    isLoading,
  } = useFetchFiles(
    `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}`
  )
  const { updateInfiniteToast } = useSocket([], [])
  const { fileMap } = useFileMap(fileData)
  const [sorting, setSorting] = useState<SortingState>([])
  const [files, setFiles] = useState<File[]>([])
  const [fileToRename, setFileToRename] = useState<CustomFileData>()
  const [showFolderModal, setShowFolderModal] = useState<boolean>(false)
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false)

  const [folderPath, setFolderPath] = useState<string[]>([ROOT_FOLDER])

  const [previousRow, setPreviousRow] = useState<Row<CustomFileData> | null>()
  const [selectedRows, setSelectedRows] = useState<Row<CustomFileData>[]>([])
  const addSelectedRows = (rows: Row<CustomFileData>[]) =>
    setSelectedRows((selectedRows) => selectedRows.concat(rows))

  const deleteSelectedRow = (row: Row<CustomFileData>) =>
    setSelectedRows((selectedRows) => selectedRows.filter((r) => r.original.id !== row.original.id))

  const resetSelection = useCallback(
    (row?: Row<CustomFileData> | undefined) => {
      if (row) {
        if (selectedRows.length <= 1) {
          if (selectedRows.includes(row)) {
            deleteSelectedRow(row)
          } else {
            setSelectedRows([row])
          }
        } else {
          setSelectedRows([row])
        }
      } else {
        setSelectedRows([])
      }
    },
    [selectedRows]
  )
  const selectedFiles = useCallback(() => selectedRows.map((r) => r.original), [selectedRows])
  const outerRef = useRef(null)
  const folderNameInputRef = useRef<HTMLInputElement>(null)
  const RenameInputRef = useRef<HTMLInputElement>(null)

  if (isError) {
    getLogger().error(error?.message)
  }
  const { onCopy, onCut, onPaste, setRenamedFile, renamedFile, countFiles, sendWsMessage } =
    useFileTable(selectedFiles())
  let currentFolder: CustomFileData[] = useMemo(() => {
    let folderContent: CustomFileData[] = []
    const folder =
      folderPath.length === 1 ? folderPath.join('/') : folderPath.slice(1).join('/').concat('/')
    fileMap[folder]?.children?.forEach((element) => {
      folderContent.push(element)
    })
    return folderContent
  }, [fileMap, folderPath])

  function getChildren(childrenIds: string[] | undefined): CustomFileData[] {
    let children: CustomFileData[] = []
    childrenIds?.forEach((value) => children.push(fileMap[value]))

    return children
  }

  const reload = useCallback(() => {
    queryClient.invalidateQueries([
      'files',
      { filePath: `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
    ])
  }, [queryClient])

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns: columns,
    data: currentFolder,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSubRows: (row) => getChildren(row.childrenIds),
    getSortedRowModel: getSortedRowModel(),
    sortingFns: {
      foldersFirstSort: (
        rowA: Row<CustomFileData>,
        rowB: Row<CustomFileData>,
        columnId: string
      ): number =>
        rowA.original.isDir ||
          rowB.original.isDir ||
          //@ts-ignore
          rowA.getValue(columnId) < rowB.getValue(columnId)
          ? 1
          : -1,
    },
    enableMultiRowSelection: true,
    enableSubRowSelection: false,
  })

  const inputFile = useRef<HTMLInputElement | null>(null)
  const inputFolder = useRef<HTMLInputElement | null>(null)
  const onUploadFileClick = () => {
    if (inputFile !== null && inputFile.current !== null) inputFile.current.click()
  }
  const onUploadFolderClick = () => {
    if (inputFolder !== null && inputFolder.current !== null) inputFolder.current.click()
  }

  function uploadFiles(files: File[]) {
    const path = folderPathRef.current.slice(1, folderPathRef.current.length).join('/')
    const uploadPromises = files.map((file: File) => {
      const fullPath = path === '' ? `${file.name}` : `${path}/${file.name}`

      return uploadFile(fullPath, file)
    })
    sendWsMessage(
      'file-upload',
      path === '' ? `${identityId.current}/` : `${identityId.current}/${path}/`,
      files.length.toLocaleString(),
      undefined
    )
    return Promise.all(uploadPromises)
  }

  const uploadFileMutation = useMutation(({ files }: { files: File[] }) => uploadFiles(files), {
    onMutate: async () => {
      await queryClient.cancelQueries([
        'files',
        { filePath: `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
      ])
      const path = folderPathRef.current.slice(1, folderPathRef.current.length).join('/')
      const tempFiles: dbItem[] = files.map<dbItem>((f) => ({
        prefix: identityId.current,
        eventTime: convertTimestampToDateString(f.lastModified),
        size: f.size,
        file: path === '' ? `${f.name}` : `${path}/${f.name}`,
      }))
      const previousFiles = queryClient.getQueryData<dbItem[]>([
        'files',
        { filePath: `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
      ]) || []
      queryClient.setQueryData<dbItem[]>([
        'files',
        { filePath: `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
      ], (currentFiles) =>
        currentFiles ? currentFiles.concat(tempFiles) : tempFiles
      )
      const rollback: () => void = () => queryClient.setQueryData([
        'files',
        { filePath: `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
      ], previousFiles)
      return { rollback }
    },
    onError: (error: any, variables, context) => {
      context?.rollback()
      getLogger().error(error)
    },
    onSettled: () => {
      toast.clearWaitingQueue()
      toast.dismiss()
    },
  })

  const renameMutation = useMutation(
    ({ props, identityId }: { props: RenameProps; identityId: string }) =>
      renameFile(props, identityId),
    {
      onMutate: async ({ props, identityId }) => {
        await queryClient.cancelQueries([
          'files',
          { filePath: `${identityId}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
        ])
        const previousFiles = queryClient.getQueryData<dbItem[]>([
          'files',
          { filePath: `${identityId}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
        ])
        if (previousFiles) {
          queryClient.setQueryData<dbItem[]>([
            'files',
            { filePath: `${identityId}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
          ], () =>
            previousFiles.map((file) => {
              if (file.file.includes(props.source)) {
                const newFile = file.file.replace(
                  props.source,
                  props.destination.slice(props.destination.indexOf('/') + 1)
                )
                return { ...file, file: newFile }
              }
              return file
            })
          )
        }
        const rollback: () => void = () => queryClient.setQueryData([
          'files',
          { filePath: `${identityId}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
        ], previousFiles)
        return { rollback }
      },
      onSuccess: () => {
        setShowRenameModal(false)
        updateInfiniteToast(toast.loading('Renaming...', toastInfiniteConfig))
        setRenamedFile('')
        setFileToRename(undefined)
      },
      onError: (error, _, context) => {
        context?.rollback()
        getLogger().error(error)
      },
    }
  )

  function handleOnDelete(fileNames: string[]) {
    return Promise.all<Promise<string>[]>(
      fileNames.filter((v) => v.length > 0).map((fileName) => removeFile(fileName))
    )
  }
  const deleteFileMutation = useMutation((fileNames: string[]) => handleOnDelete(fileNames), {
    onMutate: async (fileNames) => {
      await queryClient.cancelQueries([
        'files',
        { filePath: `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` },
      ])
      const previousFiles = queryClient.getQueryData<dbItem[]>([
        'files',
        { filePath: `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
      ])
      if (previousFiles) {
        queryClient.setQueryData<dbItem[]>([
          'files',
          { filePath: `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
        ], () =>
          previousFiles.filter((file) => !fileNames.includes(file.file))
        )
      }
      const rollback: () => void = () => queryClient.setQueryData([
        'files',
        { filePath: `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
      ], previousFiles)
      return { rollback }
    },

    onError: (_1, _2, context) => {
      context?.rollback()
    },
    onSettled: () => {
      toast('Files deleted', { ...toastDefaultConfig, type: 'success' })
      resetSelection()
    },
  })

  function addNewFiles(newFiles: File[], files: File[], multiple: boolean): File[] {
    for (let file of newFiles) {
      if (!multiple) {
        return files.concat(file)
      }
      files.push(file)
    }
    return files
  }

  const handleNewFileUpload = (e: FileList | any) => {
    let newFiles = e instanceof FileList ? e : e.target.files

    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles, files, true)
      setFiles(updatedFiles)
      uploadFileMutation.mutate({ files })
    }
  }

  const newFolderMutation = useMutation(
    (folderContent: any) => uploadFolder(folderContent, folderPathRef.current),
    {
      onMutate: async () => {
        await queryClient.cancelQueries([
          'files',
          { filePath: `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
        ])
        const previousFiles = queryClient.getQueryData<dbItem[]>([
          'files',
          { filePath: `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
        ])
        const rollback: () => void = () => queryClient.setQueryData([
          'files',
          { filePath: `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
        ], previousFiles)
        return { rollback }
      },
      onError: (error, _, context) => {
        context?.rollback()
        getLogger().error(error)
      },
      onSettled() {
        updateInfiniteToast(toast.loading('Creating folder...', toastInfiniteConfig))
      },
    }
  )

  const handleNewFolderUpload = (e: any) => {
    let folderContent = e.target.files
    if (folderContent.length) {
      const path =
        folderPathRef.current.length > 1
          ? `${identityId.current}/${folderPathRef.current
            .slice(1, folderPathRef.current.length)
            .join('/')}`
          : `${identityId.current}`
      sendWsMessage('folder-upload', path, folderContent.length.toLocaleString(), undefined)
      newFolderMutation.mutate(folderContent)
    }
  }

  const openNewFolderModal = () => {
    setShowFolderModal(true)
  }
  const closeNewFolderModal = () => {
    reset()
    setShowFolderModal(false)
  }

  const createFolderMutation = useMutation((folderName: string) => uploadFile(folderName), {
    onMutate: async () => {
      await queryClient.cancelQueries([
        'files',
        { filePath: `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
      ])
      const previousFiles = queryClient.getQueryData<dbItem[]>([
        'files',
        { filePath: `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
      ])
      const rollback: () => void = () => queryClient.setQueryData([
        'files',
        { filePath: `${identityId.current}/${folderPathRef.current.slice(1, folderPathRef.current.length).join('/')}` }
      ], previousFiles)
      return { rollback }
    },
    onError: (error, _, context) => {
      context?.rollback()
      getLogger().error(error)
    },
    onSettled: () => {
      updateInfiniteToast(toast.loading('Creating folder...', toastInfiniteConfig))
    },
  })

  const handleCreateFolder = async () => {
    const basePath =
      folderPathRef.current.length > 1
        ? folderPathRef.current.slice(1, folderPathRef.current.length).join('/')
        : ''
    let newFolderName = folderNameInputRef.current?.value
    if (newFolderName) {
      sendWsMessage(
        'folder-create',
        `${identityId.current}${basePath === '' ? '' : `/${basePath}`}/${newFolderName}`,
        '1',
        undefined
      )
      const folderName = basePath === '' ? `${newFolderName}` : `${basePath}/${newFolderName}`
      createFolderMutation.mutate(folderName)
      if (folderNameInputRef.current) {
        folderNameInputRef.current.value = ''
      }
    }
    closeNewFolderModal()
    reset()
  }

  const handleRenameSubmit = () => {
    if (fileToRename) {
      let destination: string =
        identityId.current + folderPathRef.current.join('/') + `/${RenameInputRef.current?.value}`
      if (fileToRename.isDir) destination = `${destination}/`
      const source = fileToRename.id
      if (destination) {
        renameMutation.mutate({ props: { destination, source }, identityId: identityId.current })
        const fileCount =
          fileToRename.children && fileToRename.children.length > 0
            ? countFiles(fileToRename.children) + 1 + '' // +1 for the folder itself
            : '1'
        sendWsMessage('rename', destination, fileCount, { fileName: fileToRename.name })
      }
    }
  }

  const open = async (e: SyntheticEvent) => {
    e.stopPropagation()
    const folderSelected = e.currentTarget.childNodes[0].textContent
    if (folderSelected) {
      const fileData = getRowModel().rows.find(
        (value) => value.original.name === folderSelected
      )?.original

      if (fileData) {
        if (fileData.isDir && fileData.id) {
          //opens folder
          const filePathArray = fileData.id.split('/').filter((folder) => folder !== '')

          resetSelection()
          filePathArray.unshift('')
          setFolderPath(filePathArray)
          folderPathRef.current = filePathArray
        } else {
          //opens file
          const fileDownloaded: string | Blob = await downloadFile(fileData.id, false)
          if (typeof fileDownloaded === 'string') {
            window.open(fileDownloaded)
          }
          resetSelection()
        }
      }
    }
  }

  const { show: showContextMenu, hideAll: hideContextMenu } = useContextMenu({
    id: MENU_ID,
  })

  const onRowDoubleClick = async (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: Row<CustomFileData>) => {
    hideContextMenu()
    e.stopPropagation()

    // TODO: change for the selected file (and S3 URL)
    // const fileInfo = "file:///home/cmartinez/pcqscm_manhattan_plot.box"
    // const fileInfo = "file:///home/cmartinez/dbtable.box"

    const filename = row.original.id;
    const s3Url = await getFileS3Url(filename);

    let t = toast(`Opening ${filename.substring(0, 50) + '...'}`, {
      ...toastDefaultConfig,
      hideProgressBar: true,
    })

    fetch(`${REST_SERVER}/${REST_ACTIONS.METADATA}`, {
      // TODO: once the backend is configured, remove mode
      // mode: 'no-cors',
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file: s3Url,
      })
    }) //, {signal: this.controller.signal})
      .then((response) => {
        return response.json()
      })
      .then((jsonResponse) => {
        const selectedConfig = jsonResponse;
        console.log("JSON RESPONSE", jsonResponse)

        toast.done(t)
        toast.dismiss(t)

        window.postMessage(createRequestViewerMessage(selectedConfig))
      })
      .catch((error) => {
        getLogger().error(error)
        
        toast.done(t)
        toast.dismiss(t)
      })
  }

  const onRowClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: Row<CustomFileData>
  ) => {
    hideContextMenu()
    e.stopPropagation()
    const { ctrlKey, metaKey, shiftKey, detail } = e
    const isSelected = selectedRows.includes(row)
    if (ctrlKey || metaKey) {
      isSelected ? deleteSelectedRow(row) : addSelectedRows([row])
      setPreviousRow(row)
    } else if (shiftKey && previousRow) {
      const rows = getRowModel().rows
      const lastIndex = rows.findIndex((r) => r.id === row.id)
      const index = rows.findIndex((r) => r.id === previousRow.id)
      const min = Math.min(lastIndex, index)
      const max = Math.max(lastIndex, index)
      resetSelection()
      setSelectedRows([row])
      addSelectedRows(rows.slice(min, max + 1))
    } else {
      setPreviousRow(row)
      detail === 1 ? resetSelection(row) : open(e)
    }
    selectedRows.forEach((r) => r.toggleSelected())
  }

  const onAuxClick = (row: Row<CustomFileData>) => {
    if (!selectedRows.includes(row)) {
      setSelectedRows([row])
    }
    setPreviousRow(row)
  }

  const onDropEvent = (fileList: FileList) => {
    let files: File[] = []
    for (let index = 0; index < fileList.length; index++) {
      const file = fileList.item(index)
      file && files.push(file)
    }
    uploadFiles(files)
  }

  const folderInputAttr = { directory: '', webkitdirectory: '' }

  const handleUserKeyPress = (ev: any) => {
    if ((ev.ctrlKey || ev.metaKey) && ev.key === 'v') {
      onPaste(folderPathRef.current)
      resetSelection()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  })

  const handleKeyDown = (ev: KeyboardEvent) => {
    ev.preventDefault()
    if ((ev.ctrlKey || ev.metaKey) && ev.key === 'c') {
      onCopy()
    } else if ((ev.ctrlKey || ev.metaKey) && ev.key === 'x') {
      onCut()
    }
  }

  const reset = () => {
    setPreviousRow(undefined)
    resetSelection()
  }

  const fileTableProviderValues = useMemo(() => {
    return {
      resetSelection,
      setShowDeleteConfirmation,
      setShowRenameModal,
      showDeleteConfirmation,
      showRenameModal,
      selectedFiles,
      folderPathRef,
      folderPath,
      onBreadCrumb: (e: SyntheticEvent) => {
        const folderSelected = e.currentTarget.textContent

        let pathToTarget = folderSelected
          ? folderPathRef.current.slice(0, folderPathRef.current.indexOf(folderSelected) + 1)
          : ['']
        resetSelection()
        setFolderPath(pathToTarget)
        folderPathRef.current = pathToTarget
        reload()
      },
      onNewFolder: openNewFolderModal,
      onReturn: () => {
        if (folderPathRef.current.length > 1) {
          folderPathRef.current.pop()
          const path = [...folderPathRef.current]
          setFolderPath(path)
          folderPathRef.current = path
          resetSelection()
          reload()
        }
      },
      onUploadFile: onUploadFileClick,
      onUploadFolder: onUploadFolderClick,
      fileToRename: fileToRename,
      setFileToRename: setFileToRename,
      onRename: () => {
        setFileToRename(selectedFiles()[0])
        if (selectedFiles().length === 1)
          setRenamedFile(selectedFiles().map((v) => v.name)[0] || '')
      },
    }
  }, [
    fileToRename,
    folderPath,
    setRenamedFile,
    resetSelection,
    reload,
    selectedFiles,
    showDeleteConfirmation,
    showRenameModal,
  ])

  const newFolderModal = (
    <CustomModal
      key={'new-folder-modal'}
      submit={handleCreateFolder}
      closeModal={closeNewFolderModal}
      title={'New folder'}
      submitButtonText='Create'
    >
      <input
        autoFocus
        className={'form-control form-control-white form-control-sm ps-9'}
        type='text'
        placeholder={'Untitled folder'}
        ref={folderNameInputRef}
      ></input>
    </CustomModal>
  )
  const renameModal = (
    <CustomModal
      key={'rename-modal'}
      submit={handleRenameSubmit}
      closeModal={() => {
        reset()
        setShowRenameModal(false)
      }}
      title={'Rename'}
      submitButtonText='Rename'
    >
      <input
        autoFocus
        className={'form-control form-control-white form-control-sm ps-9'}
        type='text'
        id='rename-input'
        defaultValue={renamedFile}
        ref={RenameInputRef}
      ></input>
    </CustomModal>
  )
  const deleteConfirmationModal = (
    <CustomModal
      key={'delete-confirmation-modal'}
      submit={() => {
        deleteFileMutation.mutate(findIds(selectedFiles()))
        setShowDeleteConfirmation(false)
        reset()
      }}
      closeModal={() => {
        setShowDeleteConfirmation(false)
        reset()
      }}
      title={'Confirm delete?'}
      submitButtonText='Confirm'
    >
      <>
        <p>The following files will be deleted:</p>
        <ul>
          {selectedFiles().map((file) => (
            <li key={file.id}>{file.name}</li>
          ))}
        </ul>
      </>
    </CustomModal>
  )
  return (
    <>
      {showFolderModal && newFolderModal}
      {showRenameModal && renameModal}
      {showDeleteConfirmation && deleteConfirmationModal}
      <input
        type='file'
        multiple
        id='file-upload'
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={handleNewFileUpload}
      />
      <input
        type='file'
        id='folder-upload'
        ref={inputFolder}
        style={{ display: 'none' }}
        onChange={handleNewFolderUpload}
        {...folderInputAttr}
      />

      <div className='card card-py-0 h-100'>
        <FileTableContext.Provider value={fileTableProviderValues}>
          <FileTableHeader title={props.title} collapseProps={props.collapseProps} />
          <div
            id='context-menu-container'
            className='card-body mb-4'
            onClick={(e) => {
              //@ts-ignore
              if (!e.target.offsetParent.id.includes('context')) reset()
            }}
            onContextMenu={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (e.shiftKey === false) {
                showContextMenu(e)
              }
            }}
            style={{ height: '40vh' }}
          >
            <FileTableMenu />
            {isLoading ? (
              <LoadingSpinner style={{ position: 'relative', top: '50%', left: '50%' }} />
            ) : (
              <DropZone onDrop={onDropEvent} style={{ height: 'inherit' }}>
                <table
                  className='table table-flush table-row-bordered table-row-gray-100 align-middle gs-0'
                  ref={outerRef}
                >
                  <thead>
                    {getHeaderGroups().map((headerGroup) => (
                      <tr
                        className='fw-bolder text-muted'
                        key={headerGroup.id}
                        style={{ borderBottomWidth: '0' }}
                      >
                        {headerGroup.headers.map((header) => (
                          <th key={header.id}>
                            {header.isPlaceholder ? null : (
                              <div
                                {...{
                                  className: `${header.column.getCanSort() ? 'cursor-pointer select-none' : ''
                                    } fs-4`,
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {{
                                  asc: <i className='fas fa-sort-up ps-1'></i>,
                                  desc: <i className='fas fa-sort-down ps-1'></i>,
                                }[header.column.getIsSorted() as string] ?? null}
                              </div>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody ref={outerRef}>
                    {getRowModel().rows.map((row) => (
                      <tr
                        id={row.id}
                        key={row.id}
                        onContextMenu={(e) => {
                          if (e.shiftKey === false) {
                            e.preventDefault()
                            showContextMenu(e)
                            onAuxClick(row)
                          }
                        }}
                        onClick={(e) => onRowClick(e, row)}
                        onDoubleClick={(e) => onRowDoubleClick(e, row)}
                        onKeyDown={handleKeyDown}
                        tabIndex={0}
                        className={selectedRows.includes(row) ? 'tr-selected' : undefined}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>
                            <div className='text-dark fw-normal text-hover-gray-700 mb-1 fs-6'>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </DropZone>
            )}
          </div>
        </FileTableContext.Provider>
      </div>
    </>
  )
}

export default collapsibleCard(FileTable)
