import {CustomSVG, formatBytes, getLogger} from '@/_omicsbox/helpers'
import {useContext, useEffect, useState} from 'react'

import ReactTooltip from 'react-tooltip'
import {getUsage} from '../core/_requests'
import {FileTableContext} from './FileTable'

interface HeaderProps {
  title: string
  collapseProps?: {
    'data-collapsed': boolean
    onClick: React.MouseEventHandler<HTMLButtonElement>
  }
}

export const FileTableHeader = ({title, collapseProps}: HeaderProps): JSX.Element => {
  const fileTableContext = useContext(FileTableContext)
  const [usage, setUsage] = useState<number>(0)
  useEffect(() => {
    let isCancelled = false
    const usageRequest = async (isCancelled: boolean) => {
      const usage = await getUsage(isCancelled)
      !isCancelled && setUsage(usage || 0)
    }
    usageRequest(isCancelled).catch((e) => getLogger().log(e))
    return () => {
      isCancelled = true
    }
  }, [])
  return (
    <div className='card-header border-0 pt-5' style={{minHeight: '110px'}}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
        <h2>{title}</h2>
        <button {...collapseProps} className='collapse-icon collapse-icon-top'>
          <i className='fa fa-window-minimize fs-5'></i>
        </button>
        <h4 style={{color: 'grey', alignContent: 'center'}}>
          {`Total usage: ${formatBytes(usage)}`}
        </h4>
        <div className='card-toolbar'>
          {fileTableContext.folderPath.length > 1 && (
            <h3 className='card-title align-items-start flex-column'>
              <ol className='breadcrumb text-muted fs-6 fw-semibold'>
                {fileTableContext.folderPath.map((folder) => (
                  <li
                    key={folder}
                    className='breadcrumb-item pe-3'
                    onClick={(e) => fileTableContext.onBreadCrumb(e)}
                  >
                    <div className='card-label fw-bolder fs-2 mb-1 text-hover-primary cursor-pointer'>
                      {folder === '' ? (
                        <i
                          style={{cursor: 'pointer', textAlign: 'center', userSelect: 'none'}}
                          className='fas fa-home-alt'
                        ></i>
                      ) : (
                        folder
                      )}
                    </div>
                  </li>
                ))}
              </ol>
            </h3>
          )}
        </div>
      </div>
      <div className='card-toolbar'>
        <ul className='nav'>
          <li>
            <button
              data-tip
              data-for='create-folder'
              className='btn'
              onClick={() => fileTableContext.onNewFolder()}
            >
              <CustomSVG
                className='svg-icon-1 nav-link'
                path='media/icons/duotune/files/fil013.svg'
              ></CustomSVG>
            </button>
            <ReactTooltip id='create-folder' effect='solid'>
              <span>New folder</span>
            </ReactTooltip>
          </li>
          <li>
            <button
              data-tip
              data-for='upload-file'
              id='upload-file'
              className='btn'
              data-bs-toggle='tab'
              onClick={() => fileTableContext.onUploadFile()}
            >
              <CustomSVG
                className='svg-icon-1 nav-link'
                path='media/icons/duotune/files/fil010.svg'
              ></CustomSVG>
            </button>
            <ReactTooltip id='upload-file' effect='solid'>
              <span>Upload File</span>
            </ReactTooltip>
          </li>
          <li>
            <button
              data-tip
              data-for='upload-folder'
              className='btn'
              data-bs-toggle='tab'
              onClick={() => fileTableContext.onUploadFolder()}
            >
              <CustomSVG
                className='svg-icon-1 nav-link'
                path='media/icons/duotune/files/fil018.svg'
              ></CustomSVG>
            </button>
            <ReactTooltip id='upload-folder' effect='solid'>
              <span>Upload folder</span>
            </ReactTooltip>
          </li>
        </ul>
      </div>
    </div>
  )
}
