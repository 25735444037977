import React, {MouseEventHandler} from 'react'
import {ComponentType} from 'react'
import ReactDOM from 'react-dom'

type collapsibleState = {
  collapsed: boolean
}

export interface collapseProps {
  title: string
  collapseProps?: {
    'data-collapsed': boolean
    onClick: MouseEventHandler<HTMLButtonElement>
  }
}

export function collapsibleCard<T extends collapseProps>(Component: ComponentType<T>) {
  return class PP extends React.Component<T, collapsibleState> {
    targetEl?: HTMLElement | null

    holdEl = document.createElement('div')

    constructor(props: T) {
      super(props)

      this.state = {
        collapsed: false,
      }

      this.onCollapseChange = this.onCollapseChange.bind(this)
    }

    onCollapseChange() {
      this._findTarget()

      // Avoid collapsing if holder does not exists
      if (this.targetEl) {
        this.setState((state: collapsibleState, props) => {
          return {
            collapsed: !state.collapsed,
          }
        })
      }
    }

    _findTarget() {
      if (!this.targetEl) {
        this.targetEl = document.getElementById('minimized_cards_holder')

        if (this.targetEl) {
          this.targetEl.appendChild(this.holdEl)
        }
      }
    }

    componentDidMount() {
      this._findTarget()
    }

    componentWillUnmount() {
      if (this.targetEl) {
        this.targetEl.removeChild(this.holdEl)
      }
    }

    render() {
      const {collapsed} = this.state
      const newProps: collapseProps = {
        title: this.props.title,
        collapseProps: {
          'data-collapsed': collapsed,
          onClick: this.onCollapseChange,
        },
      }

      // @ts-ignore : TS2322
      let renderEl = (
        <div className='col-xxl collapsible-holder' data-collapsed={collapsed}>
          <Component {...this.props} {...newProps} />
        </div>
      )

      if (collapsed) {
        // renderEl = ReactDOM.createPortal(renderEl, this.holdEl);
        let portalEl = ReactDOM.createPortal(
          <div className='collapsed-viewer'>
            <h2>{this.props.title}</h2>
            <button className='collapse-icon' onClick={this.onCollapseChange}>
              <i className='fa fa-window-maximize fs-5'></i>
            </button>
          </div>,
          this.holdEl
        )

        renderEl = (
          <>
            {renderEl}
            {portalEl}
          </>
        )
      }

      return renderEl
    }
  }
}
