import {useEffect} from 'react'

interface ModalProps {
  closeModal: () => void
  children?: JSX.Element
  submit?: () => void
  title: string
  submitButtonText: string
}

export const CustomModal = ({
  submit,
  children,
  title,
  closeModal,
  submitButtonText,
}: ModalProps) => {
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        event.stopPropagation()
        if (submit) {
          submit()
          closeModal()
        }
      } else if (event.key === 'Escape') {
        event.preventDefault()
        event.stopPropagation()
        closeModal()
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [submit, closeModal])

  return (
    <>
      <div className={`modal-backdrop display-block show`}></div>
      <div className={`modal fade display-block show`}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header' style={{borderBottom: '0px'}}>
              <h5 className='modal-title'>{title}</h5>
            </div>
            <div className='modal-body py-2'>{children}</div>
            <div className='modal-footer' style={{borderTop: '0px'}}>
              <button type='button' className='btn btn-light' onClick={closeModal}>
                Cancel
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => {
                  submit && submit()
                  closeModal()
                }}
              >
                {submitButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
