import {getLogger} from '@/_omicsbox/helpers'
import {useQueryClient} from '@tanstack/react-query'
import React, {createContext, FC, ReactNode, useCallback, useEffect, useRef, useState} from 'react'
import {Id, toast} from 'react-toastify'
const wsUrl = process.env.REACT_APP_WS_URL || ''
const SocketContext = createContext<
  {socket: WebSocket | null; updateInfiniteToast: (toastId: Id | undefined) => void} | undefined
>(undefined)

const SocketProvider: FC<{children: ReactNode}> = ({children}: {children: ReactNode}) => {
  const [reconnectAttempts, setReconnectAttempts] = useState(0)
  const [socket, setSocket] = useState<WebSocket | null>(null)
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const queryClient = useQueryClient()
  const infiniteToast = useRef<Id | undefined>(undefined)

  const connectSocket = useCallback(() => {
    let nextSocket: WebSocket | null = null
    try {
      nextSocket = openConnection()
    } catch (error: any) {
      getLogger().error(`Websocket connection failed due to: ${error.message}`)
    }

    if (nextSocket) {
      nextSocket.onopen = () => {
        console.log('Socket connected at:', new Date().toLocaleString())
      }
      nextSocket.onerror = (err) => {
        console.log('Socket error', err)
        infiniteToast.current &&
          toast.update(infiniteToast.current, {
            render: 'Action failed, please try again later',
            type: 'error',
            isLoading: false,
            autoClose: 3000,
          })
      }
      nextSocket.onmessage = (ev) => {
        console.log('Socket message', ev)
        const data = JSON.parse(ev.data)
        if (data.message.jobs) {
          queryClient.invalidateQueries(['history'])
        } else if (typeof data.message === 'object' && Object.keys(data.message).length === 0) {
          queryClient.invalidateQueries(['files'])
          infiniteToast.current &&
            toast.update(infiniteToast.current, {
              render: 'Action completed',
              type: 'success',
              isLoading: false,
              autoClose: 3000,
            })
        }
      }
      nextSocket.onclose = (ev) => {
        console.log('Socket disconnected at:', new Date().toLocaleString())
        nextSocket?.close(1000, 'Socket disconnected')
        setSocket(null)
        if (ev.reason !== 'logout' && reconnectTimeoutRef.current === null) {
          const nextReconnectAttempts = reconnectAttempts + 1
          console.log('Socket reconnecting at:', new Date().toLocaleString())
          setReconnectAttempts(nextReconnectAttempts)
          if (nextReconnectAttempts < 5) {
            reconnectTimeoutRef.current = setTimeout(() => {
              reconnectTimeoutRef.current = null
              if (nextSocket?.CLOSED) {
                connectSocket()
              }
            }, Math.min(1000 * 2 ** reconnectAttempts, 30000))
          }
        }
      }
      setSocket(nextSocket)
    }
  }, [queryClient, reconnectAttempts])

  useEffect(() => {
    connectSocket()
    return () => {
      if (reconnectTimeoutRef.current !== null) {
        clearTimeout(reconnectTimeoutRef.current)
        reconnectTimeoutRef.current = null
      }
    }
  }, [connectSocket])

  const memoizedValues = React.useMemo(
    () => ({
      socket,
      updateInfiniteToast: (toastId: Id | undefined) => {
        infiniteToast.current = toastId
      },
    }),
    [socket]
  )

  return <SocketContext.Provider value={memoizedValues}>{children}</SocketContext.Provider>
}
const openConnection = (): WebSocket => {
  const user =
    localStorage.getItem('cognito-user') && JSON.parse(localStorage.getItem('cognito-user')!)
  return new WebSocket(`${wsUrl}?token=${user.signInUserSession.idToken.jwtToken}`)
}
const useSocket = (eventNames: string[], eventHandlers: EventListenerOrEventListenerObject[]) => {
  const context = React.useContext(SocketContext)
  if (context === undefined) {
    throw new Error('useSocket must be used within a SocketProvider')
  }

  useEffect(() => {
    for (let i = 0; i < eventNames.length; i++) {
      const eventName = eventNames[i]
      getLogger().info('WebSocket: adding listener', eventName)
      context.socket && context.socket.addEventListener(eventName, eventHandlers[i])
    }
    return () => {
      for (let i = 0; i < eventNames.length; i++) {
        const eventName = eventNames[i]
        getLogger().info('WebSocket: removing listener', eventName)
        context.socket && context.socket.removeEventListener(eventName, eventHandlers[i])
      }
    }
  })

  return context
}

export {SocketProvider, useSocket}
