import clsx from 'clsx'
import React, {Component} from 'react'
import {v4 as uuidv4} from 'uuid'
import {collapseProps, collapsibleCard} from './collapsible-card'
import {ExternalViewer} from './external-viewer'
import {
  createCloseViewerMessage,
  createOpenViewerMessage,
  ExternalViewerConfig,
  ExternalViewerKey,
  HandlerAction,
  HandlerActionTable,
  HandlerResponse,
  MessageHandlerContext,
  ViewerMode,
  REST_SERVER} from './handler-structures'
import styles from './style.module.css'
import {WebSocketRequest} from './websocket-request.mjs'

type ManagerState = {
  viewersConfigs: ExternalViewerConfig[]
  selectedTab: number
}

// With the current implementation, they must match with a method inside this component-
// export const VIEWER_OPENED = "addedViewer";
// export const VIEWER_CLOSE = "closedViewer";
// export const VIEWER_LIST = "listViewers";

export const OMICSBOX_BROADCAST_CHANNEL_ID = 'omicsbox_viewer_manager'
export const OMICSBOX_BACKCHANNEL_WS_SERVER_URL = process.env.REACT_APP_BACKCHANNEL_WS_SERVER_URL

const PING_TIMEOUT = 10000
const WAKEUP_TIMEOUT = 5000

const ENABLE_BACKCHANNEL = false

// const OMICSBOX_ORIGIN = process.env.REACT_APP_ORIGIN_URL
// Env variables need to be prefixes with REACT_APP_

// type HandlerAction = keyof HandlerActionTable

// function apiAction(action: HandlerAction) {
//     return function (target: any, propertyKey: string) {
//         // descriptor.enumerable = value;
//         console.log(target, propertyKey);
//         target.callbackTable[action] = target[propertyKey];
//         console.log(target.callbackTable, target.prototype);
//     };
// }

// function apiActionMethod(action: HandlerAction) {
//     return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
//         // descriptor.enumerable = value;
//         console.log(target, propertyKey, descriptor);
//         return target;
//     };
// }

/**
 * Provides a tabbed interface managing the different external viewers while providing the required
 * MessageHandler context provider.
 *
 * It listens to window messages and accepts any 'HandlerAction' that must be specified as message's
 * data.action value.
 *
 * Currently the actions supported are:
 *    - requestViewer: message created using ViewerManager.createRequestViewerMessage
 *    - openViewer: message created using ViewerManager.createOpenViewerMessage
 *    - closeViewer: message created using ViewerManager.createCloseViewerMessage
 *
 *
 */
class ViewerManager extends Component<collapseProps, ManagerState> implements HandlerActionTable {
  initializedViewers: {[key: ExternalViewerKey]: React.RefObject<HTMLIFrameElement>}
  backchannelWS: WebSocketRequest
  id: string

  _pingInterval?: ReturnType<typeof setInterval>
  _lastWakeupCheck: number
  _wakeupInterval?: ReturnType<typeof setInterval>

  constructor(props: collapseProps) {
    super(props)

    this.state = {
      viewersConfigs: [],
      selectedTab: -1,
    }

    this.initializedViewers = {}
    this.id = uuidv4()

    if (ENABLE_BACKCHANNEL) {
      this.backchannelWS = new WebSocketRequest({
        url: OMICSBOX_BACKCHANNEL_WS_SERVER_URL,
        serverCallback: this.wsMessageHandler.bind(this),
      })
    } else {
      this.backchannelWS = new WebSocketRequest({websocket: {addEventListener: () => {}}})
    }

    this._lastWakeupCheck = Date.now()
  }

  componentDidMount() {
    window.addEventListener('message', this.handleMessageEvent)

    if (ENABLE_BACKCHANNEL) {
      this._wakeupInterval = setInterval(this.wakeupCheck.bind(this), WAKEUP_TIMEOUT)
      this._pingInterval = setInterval(this.pingBackchannel.bind(this), PING_TIMEOUT)

      window.addEventListener('online', this.registerBackchannel.bind(this))

      this.registerBackchannel()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessageEvent)

    if (ENABLE_BACKCHANNEL) {
      window.removeEventListener('online', this.registerBackchannel)

      clearInterval(this._pingInterval)
      clearInterval(this._wakeupInterval)

      this.unregisterBackchannel()
    }
  }

  wsMessageHandler = (msg: string | JSON) => {
    console.log('Message received WS', msg)

    const _messageHandlers: Record<string, any> = {
      changeViewerStatus: (msgData: any) => {
        console.log('Changing viewer status')
        const viewerStatus = msgData.data

        this.changeViewerStatus(viewerStatus.data.viewerId, viewerStatus.data.mode)

        this.forceUpdate()

        // Send the confirmation back to the channel using the same reqid
        // (thus avoiding sendJson and using trySend directly)
        this.backchannelWS.trySend({
          reqid: msgData.reqid,
          data: {
            action: 'changeViewerStatus',
            changed: true,
            viewerId: viewerStatus.viewerId,
          },
        })
      },

      // 'isViewerLocked': (msgData: any) => {
      //   const config = msgData.data.config;

      //   if (config) {
      //     // TODO: temp property names
      //     config.writable = ! msgData.data.locked;

      //     window.postMessage(ViewerManager.createOpenViewerMessage(config));
      //   } else {
      //     console.log(`Viewer config with not found in message.`);
      //   }
      // }
    }

    // Note: ws server returns json objects already.
    const msgData = typeof msg === 'string' ? JSON.parse(msg) : msg
    const msgAction = msgData.data.action as string

    if (msgAction in _messageHandlers) {
      _messageHandlers[msgAction](msgData)
    } else {
      console.log(`Unimplemented action: ${msgData.action}`)
    }
  }

  private changeViewerStatus(viewerId: string, status: ViewerMode) {
    const viewerConfig = this.state.viewersConfigs.find((config) => config.objectInfo.id === viewerId)

    if (viewerConfig) {
      viewerConfig.mode = status
    }

    this.forceUpdate()
  }

  private registerBackchannel = () => {
    this.backchannelWS.sendJson(
      {
        action: 'register',
        viewer: {
          id: this.id,
          viewers: this.state.viewersConfigs,
        },
      },
      (data: any) => {
        const requestedChanges = data.modeChanges ?? {}

        Object.keys(requestedChanges).forEach((viewerId) => {
          this.changeViewerStatus(viewerId, requestedChanges[viewerId])
        })
      }
    )
  }

  private unregisterBackchannel = () => {
    this.backchannelWS.sendJson({
      action: 'unregister',
      viewer: {
        id: this.id,
      },
    })
  }

  private wakeupCheck = () => {
    let currentTime = Date.now()

    if (currentTime > this._lastWakeupCheck + WAKEUP_TIMEOUT + 5000) {
      this.registerBackchannel()
    }

    this._lastWakeupCheck = currentTime
  }

  private pingBackchannel = () => {
    this.backchannelWS.sendJson(
      {
        action: 'ping',
        viewer: {
          id: this.id,
        },
      },
      (data: any) => {
        // Server will answer with success=true if the viewer is registered.
        if (data.success === false) {
          this.registerBackchannel()
        }
      }
    )
  }

  private updateViewers = () => {
    this.backchannelWS.sendJson({
      action: 'updateViewers',
      viewer: {
        id: this.id,
        viewers: this.state.viewersConfigs,
      },
    })
  }

  private getPropsById = (id: ExternalViewerKey): ExternalViewerConfig | undefined => {
    return this.state.viewersConfigs.find((item) => String(item.objectInfo.id) === String(id))
  }

  private getPropsByRef = (ref: MessageEventSource): ExternalViewerConfig | undefined => {
    // ref instanceof Window does not seem to work, even though "ref" is a Window
    if ('frameElement' in ref) {
      const sourceIframe = ref.frameElement as HTMLIFrameElement
      const iframeId = Object.keys(this.initializedViewers).find(
        (key) => this.initializedViewers[key].current === sourceIframe
      )

      if (iframeId) {
        return this.getPropsById(iframeId)
      }
    }

    return undefined
  }

  getApiUrl = (e: MessageEvent): void => {
    console.log('RETRIEVED CONFIG', e)

    if (e.source) {
      const iframeEl = e.source
      const viewerProps = this.getPropsByRef(iframeEl)


      // const apiURL = '${REST_SERVER/${REST_ACTIONS.GET_API_URL}/${viewerProps.objectInfo.id}'

      if (viewerProps) {
        const actionResponse = {
          reqId: viewerProps.objectInfo.id,
          value: `${REST_SERVER}/controller/${viewerProps.controllerInfo.controllerEndpoint}`,
          action: "getApiUrl"
        }

        // console.log('SENDING EVENT', actionResponse)
        // @ts-ignore
        iframeEl.postMessage(actionResponse, '/')
      }
    }
  }

  // addedViewer = (e: MessageEvent) => {
  //   console.log("OPENED VIEWER", e.data.value);
  //   /*
  //     Opening a new tab will start with a clean
  //   */
  //   const openedViewerConfig = e.data.value;

  //   this.sessionViewers.add(openedViewerConfig.id);
  // }

  // closedViewer = (e: MessageEvent) => {
  //   console.log("CLOSED VIEWER", e.data.value);
  //   /*
  //     Opening a new tab will start with a clean
  //   */
  //   const openedViewerConfig = e.data.value;

  //   this.sessionViewers.delete(openedViewerConfig.id);
  // }

  // listViewers = (e: MessageEvent) => {
  //   console.log("CLOSED VIEWER", e.data.value);
  //   /*
  //     Opening a new tab will start with a clean
  //   */
  //   const viewersListIds = this.state.viewersConfigs.map(config => config.id);

  //   // this.sessionViewers.delete(openedViewerConfig.id);
  // }

  openViewer = (e: MessageEvent) => {
    console.log('OPEN VIEWER MESSAGE', e)

    const viewerConfig = e.data.value as ExternalViewerConfig

    if (!this.state.viewersConfigs.some((config) => config.objectInfo.id === viewerConfig.objectInfo.id)) {
      // if (!this.sessionViewers.has(viewerConfig.id)) {
      const newViewers = this.state.viewersConfigs.concat(viewerConfig)

      this.setState(
        {
          viewersConfigs: newViewers,
          selectedTab: newViewers.length - 1,
        },
        this.updateViewers
      )
    } else {
      console.log('Viewer already opened')
    }
  }

  // hasViewer = (e: MessageEvent) => {
  //   console.log("ASKING VIEWER MESSAGE", e);
  //   const viewerConfig = e.data.value;
  //   const hasViewerOpened = this.state.viewersConfigs.some(config => config.id === viewerConfig.id);

  //   this.sharedWorker?.port.postMessage({
  //     action: 'hasViewer',
  //     data: {
  //       config: viewerConfig,
  //       isOpened: hasViewerOpened
  //     }
  //   });
  // }

  // changeViewerStatus = (e: MessageEvent) => {
  //   console.log("CHANGING VIEWER STATUS", e);

  //   const viewerConfig = e.data.value;

  //   this.backchannelWS.sendJson({
  //     action: 'requestOwnership',
  //     viewer: {
  //       id: this.id,
  //       config: viewerConfig
  //       // viewerId: viewerConfig.id.toString()
  //     }
  //   });
  // }

  requestViewer = (e: MessageEvent) => {
    const viewerConfig = e.data.value as ExternalViewerConfig

    if (
      //!viewerConfig.locked &&
      !this.state.viewersConfigs.some(
        (config) => config.objectInfo.id.toString() === viewerConfig.objectInfo.id.toString()
      )
    ) {
      // if (!this.sessionViewers.has(viewerConfig.id)) {
      //const newViewers = this.state.viewersConfigs.concat(viewerConfig)

      if (ENABLE_BACKCHANNEL) {
        this.backchannelWS.sendJson(
          {
            action: 'isViewerLocked',
            viewer: {
              id: this.id,
              config: viewerConfig,
              // viewerId: viewerConfig.id.toString()
            },
          },
          (response: any) => {
            console.log('IS VIEWER LOCKED RESPONSE', response)
            const isLocked = response.locked
            const viewerConfig = response.config

            viewerConfig.mode = isLocked ? ViewerMode.READ : ViewerMode.WRITE

            window.postMessage(createOpenViewerMessage(viewerConfig), '/')
          }
        )
      } else {
        window.postMessage(createOpenViewerMessage(viewerConfig), '/')
      }
    } else {
      alert('Viewer already opened in this manager. Testing message to be removed ;)')
    }
  }

  closeViewer = (e: MessageEvent) => {
    console.log('CLOSE VIEWER MESSAGE', e)

    const viewerConfig = e.data.value as ExternalViewerConfig

    if (this.state.viewersConfigs.some((config) => config.objectInfo.id === viewerConfig.objectInfo.id)) {
      // if (this.sessionViewers.has(viewerConfig.id)) {
      const newViewers = this.state.viewersConfigs.filter((config) => config.objectInfo.id !== viewerConfig.objectInfo.id)

      this.setState(
        {
          viewersConfigs: newViewers,
          selectedTab: newViewers.length - 1,
        },
        this.updateViewers
      )
    } else {
      console.log('Viewer not opened')
    }
  }

  handleMessageEvent = (e: MessageEvent) => {
    // if (e.origin !== OMICSBOX_ORIGIN) {
    //   console.log('Different origins', OMICSBOX_ORIGIN, e.origin)
    // } else {
    //   console.log('PARENT COMPONENT MESSAGE RECEIVED', e)
    // }

    const handlerAction = e.data.action as HandlerAction

    // console.log('VIEWER MANAGER COMPONENT MESSAGE RECEIVED', e, handlerAction)

    // if (handlerAction in this.handlerCallbacks && e.source) {
    if (handlerAction in this) {
      console.log('ACTION AVAILABLE')
      this[handlerAction](e)

      // Now we can receive messages from different elements, not only iframes
      // if (e.source) {
      //   const iframeEl = e.source
      //   const viewerProps = this.getPropsByRef(iframeEl)

      //   if (viewerProps) {
      //     const actionResponse = this[handlerAction](viewerProps)

      //     console.log('SENDING EVENT', actionResponse)
      //     iframeEl.postMessage(actionResponse)
      //   }
      // } else {
      //   console.log("MESSAGE WITHOUT SOURCE")
      //   this[handlerAction]();
      // }
    }
  }

  registerViewer = (key: string, iframe: React.RefObject<HTMLIFrameElement>) => {
    // TODO: warning/error when we already have a viewer with the same key?
    this.initializedViewers[key] = iframe
  }

  unregisterViewer = (key: string) => {
    // TODO: warning/error when we already have a viewer with the same key?
    delete this.initializedViewers[key]
  }

  sendMessageToViewer = (iframe: React.RefObject<HTMLIFrameElement>, response: HandlerResponse) => {
    if (iframe.current?.contentWindow) {
      iframe.current.contentWindow.postMessage(response)
    }
  }

  handleRequestMode = (key: string, mode: ViewerMode) => {
    this.backchannelWS.sendJson(
      {
        action: 'requestOwnership',
        requestedViewerId: key,
        requestedMode: mode,
        viewer: {
          id: this.id,
          // viewerId: viewerConfig.id.toString()
        },
      },
      (message: any) => {
        console.log('RECEIVED RESPONSE FROM BACKCHANNEL', message)

        const availableMode = message.mode ?? ViewerMode.READ
        const oldConfig = this.state.viewersConfigs.find(
          (config) => String(config.objectInfo.id) === String(key)
        )

        if (oldConfig) {
          oldConfig.mode = availableMode
        }

        this.forceUpdate()
        this.updateViewers()
      }
    )
  }

  getMessageHandlerProvider() {
    return {
      registerViewer: this.registerViewer,
      unregisterViewer: this.unregisterViewer,
      sendMessage: this.sendMessageToViewer,
      handleAction: this.handleMessageEvent,
      requestMode: this.handleRequestMode,
    }
  }

  render() {
    return (
      <MessageHandlerContext.Provider value={this.getMessageHandlerProvider()}>
        {this.state.viewersConfigs.length < 1 ? null : (
          <div
            className={clsx('ag-theme-metronic card mb-5 w-100 h-100 mb-xl-8', {
              'd-none': this.state.viewersConfigs.length < 1,
            })}
          >
            <div className='card-header border-0 pt-5' style={{minHeight: 60}}>
              <div className='w-100 d-flex p-0 justify-content-between align-items-start'>
                <h2>{this.props.title}</h2>
                <button {...this.props.collapseProps} className='collapse-icon collapse-icon-top'>
                  <i className='fa fa-window-minimize fs-5'></i>
                </button>
              </div>
            </div>
            <div className='card-body pb-3 pt-0'>
              <ul
                className={clsx('nav nav-tabs nav-line-tabs fs-6', {[styles.listTab]: true})}
                role='tablist'
              >
                {this.state.viewersConfigs.map((item, index) => (
                  <li className='nav-item' key={'li_' + item.objectInfo.id}>
                    <div
                      className={clsx(`nav-link cursor-pointer`, {
                        [styles.viewerTab]: true,
                        active: index === this.state.selectedTab,
                        [styles.activeTab]: index === this.state.selectedTab,
                      })}
                      data-bs-toggle='tab'
                      role='tab'
                      onClick={() => {
                        if (this.state.selectedTab !== index) {
                          this.setState({selectedTab: index})
                        }
                      }}
                    >
                      {item.objectInfo.name}{' '}
                      <i
                        className='far fa-window-close icon-10x ms-1'
                        onClick={() => {
                          window.postMessage(createCloseViewerMessage(item))
                        }}
                      ></i>
                    </div>
                  </li>
                ))}
              </ul>

              <div
                className={clsx('tab-content rounded-bottom rounded-right', {
                  [styles.tabContent]: true,
                })}
                id='myTabContentViewer'
              >
                {this.state.viewersConfigs.map((item, index) => (
                  <div
                    className={clsx('tab-pane fade', {
                      active: this.state.selectedTab === index,
                      show: this.state.selectedTab === index,
                      [styles.tabPanel]: true,
                    })}
                    role='tabpanel'
                    key={'tab_' + item.objectInfo.id}
                  >
                    <ExternalViewer
                      key={item.objectInfo.id}
                      // Spread props from item
                      {...item}                      
                      />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </MessageHandlerContext.Provider>
    )
  }
}

export default collapsibleCard(ViewerManager)
