/* eslint-disable jsx-a11y/anchor-is-valid */
import {Auth} from '@aws-amplify/auth/lib-esm/Auth'
import {CognitoHostedUIIdentityProvider as IdentityProvider} from '@aws-amplify/auth/lib-esm/types'
import {CognitoUser} from 'amazon-cognito-identity-js'
import clsx from 'clsx'
import {useFormik} from 'formik'

import {useState} from 'react'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../_omicsbox/helpers'
import {getLogger} from '../../../../_omicsbox/helpers/logUtils'
import {toastDefaultConfig} from '../../../../_omicsbox/toastConfig'
import {useCognitoAuth} from '../core/CognitoAuth'
import {UserAttributes} from '../core/_models'
import {cognitologin} from '../core/_requests'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export const signInGoogle = async () => {
  try {
    await Auth.federatedSignIn({
      provider: IdentityProvider.Google,
    })
  } catch (error) {
    //@ts-ignore
    toast(error.message, {...toastDefaultConfig, type: 'error'})
    getLogger().error(error)
  }
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveCognitoAuth, setCurrentUser} = useCognitoAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const user: CognitoUser = await cognitologin(values.email, values.password)
        if (user) {
          user.getUserAttributes((error, attributes) => {
            if (error) {
              throw new Error(error.message)
            }
            //assign values to currentUser
            const currentUser: UserAttributes = {
              email:
                attributes!.find((attribute) => attribute.getName() === 'email')?.getValue() || '',
              name:
                attributes!.find((attribute) => attribute.getName() === 'given_name')?.getValue() ||
                '',
              identity_id:
                attributes!
                  .find((attribute) => attribute.getName() === 'custom:identityId')
                  ?.getValue() || '',
              email_verified:
                attributes!
                  .find((attribute) => attribute.getName() === 'email_verified')
                  ?.getValue() === 'true',
              sub: attributes!.find((attribute) => attribute.getName() === 'sub')?.getValue() || '',
              updated_at:
                attributes!.find((attribute) => attribute.getName() === 'updated_at')?.getValue() ||
                '',
            }
            setCurrentUser(currentUser)
            saveCognitoAuth(user)
          })
        }
      } catch (error) {
        saveCognitoAuth(undefined)
        setCurrentUser(undefined)
        setStatus('Invalid username or password.')
        setSubmitting(false)
        setLoading(false)

        //@ts-ignore
        toast(error.message, {...toastDefaultConfig, type: 'error'})
        getLogger().error(error)
      }
    },
  })

  return (
    <div className='modal-dialog-cognito'>
      <div className='modal-content background-customizable modal-content-desktop visible-md visible-lg'>
        {/* begin::Heading */}
        <div className='text-center banner-customizable'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/ob_web.png')} className='h-95px' />
        </div>
        {/* begin::Heading */}

        <div className='modal-body'>
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          <div className='row'>
            <div className='panel panel-right-border col-md-6 col-lg-6'>
              <span className='idpDescription-customizable'>Sign In with your social account</span>

              <button
                name='googleSignUpButton'
                onClick={signInGoogle}
                className='btn google-button socialButton-customizable'
              >
                <span>
                  <svg
                    className='social-logo'
                    viewBox='0 0 256 262'
                    xmlns='http://www.w3.org/2000/svg'
                    preserveAspectRatio='xMidYMid'
                  >
                    <path
                      d='M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027'
                      fill='#4285F4'
                    ></path>
                    <path
                      d='M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1'
                      fill='#34A853'
                    ></path>
                    <path
                      d='M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782'
                      fill='#FBBC05'
                    ></path>
                    <path
                      d='M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251'
                      fill='#EA4335'
                    ></path>
                  </svg>
                </span>
                <span>Continue with Google</span>
              </button>

              <br />
              <span className='legalText-customizable'>
                We won't post to any of your accounts without asking first
              </span>
            </div>

            <div className='login-or col-md-1 col-lg-1'>
              <hr className='hr-or' />
              <span className='span-or background-customizable'>or</span>
            </div>
            <div className='panel panel-left-border col-md-6 col-lg-6'>
              <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                <div className='span-or-verical background-customizable'>or</div>
                <span className='textDescription-customizable '>
                  Sign in with your email and password
                </span>
                {((formik.touched.password && formik.errors.password) ||
                  (formik.touched.email && formik.errors.email)) && (
                  <p
                    style={{whiteSpace: 'pre-line'}}
                    id='loginErrorMessage'
                    className='errorMessage-customizable'
                  >
                    Invalid username or password.
                  </p>
                )}
                <label htmlFor='signInFormUsername' className='label-customizable'>
                  Email
                </label>
                <div>
                  <input
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control inputField-customizable',
                      {'is-invalid': formik.touched.email && formik.errors.email},
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                    id='signInFormUsername'
                    required
                  />
                </div>
                <label htmlFor='signInFormPassword' className='label-customizable'>
                  Password
                </label>
                <div>
                  <input
                    id='signInFormPassword'
                    type='password'
                    className={clsx(
                      'form-control inputField-customizable',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                    placeholder='Password'
                    {...formik.getFieldProps('password')}
                  />
                </div>
                <div>
                  <Link to='/auth/forgot-password' className='redirect-customizable'>
                    Forgot your password ?
                  </Link>
                </div>
                <button
                  type='submit'
                  className='btn btn-primary submitButton-customizable'
                  disabled={loading || formik.isSubmitting || !formik.isValid}
                >
                  Sign in
                </button>
                <br />
                <div>
                  <p className='redirect-customizable'>
                    <span>Need an account?</span>&nbsp;
                    <Link to='/auth/registration' className='link-primary fw-bolder'>
                      Sign up
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
