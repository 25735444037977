import {useQuery} from '@tanstack/react-query'
import {
  getFilesV2,
  getHistory,
  getSubscriptionId,
} from '../../../app/modules/widgets/core/_requests'
import {dbItem, Job} from '../crud-helper/models'
import {getLogger} from '../logUtils'

export const useFetchFiles = (filePath: string) => {
  return useQuery<
    dbItem[] | undefined,
    Error,
    dbItem[] | undefined,
    (
      | string
      | {
          filePath: string
        }
    )[]
  >(['files', {filePath}], () => getFilesV2(filePath), {
    staleTime: 1000 * 60 * 60,
  })
}

export const useFetchHistory = ({
  startCreationTime,
  endCreationTime,
}: {
  startCreationTime: number
  endCreationTime: number
}) => {
  return useQuery<Job[] | undefined, Error>(
    ['history', {startCreationTime, endCreationTime}],
    async ({queryKey}) => {
      // @ts-ignore: unknown type
      const [, {startCreationTime, endCreationTime}] = queryKey
      let userId: string | undefined

      try {
        userId = await getSubscriptionId()
      } catch (error: any) {
        getLogger().error(error.message)
      }

      let yearBeforeNowDate = new Date(Date.now())
      if (!startCreationTime) {
        yearBeforeNowDate.setFullYear(yearBeforeNowDate.getFullYear() - 1)
      }
      return getHistory({
        userId: userId || '',
        startCreationTime: startCreationTime || yearBeforeNowDate.getTime(),
        endCreationTime: endCreationTime || Date.now(),
      })
    },
    {
      staleTime: 1000 * 60 * 60,
      // refetchInterval: 1000 * 60
    }
  )
}
