import {toAbsoluteUrl} from '@/_omicsbox/helpers'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {getLogger} from '../../../../_omicsbox/helpers/logUtils'
import {useCognitoAuth} from '../core/CognitoAuth'
import {requestPassword} from '../core/_requests'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  var {currentUser, setCurrentUser} = useCognitoAuth()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(() => {
            getLogger().log('Password reset email sent')
            if (currentUser) {
              currentUser.email = values.email
            } else {
              currentUser = {
                email: values.email,
                name: '',
                sub: '',
                identity_id: '',
                email_verified: false,
                updated_at: '',
              }
            }
            setCurrentUser(currentUser)
            setHasErrors(false)
            setLoading(false)
            navigate('/auth/forgot-password-validation')
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <>
      <div className='container'>
        <div className='modal-dialog-cognito'>
          <div className='modal-content background-customizable modal-content-mobile'>
            <div className='text-center banner-customizable'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/ob_web.png')} className='h-95px' />
            </div>
            <div className='modal-body'>
              <div>
                <div>
                  <h1>Forgot your password?</h1>
                  <span>
                    Enter your Email below and we will send a message to reset your password
                  </span>

                  {hasErrors === true && (
                    <div className='mt-10 mb-lg-15 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>
                        Sorry, looks like there are some errors detected, please try again.
                      </div>
                    </div>
                  )}

                  {hasErrors === false && (
                    <div className='mb-10 bg-light-info p-8 rounded'>
                      <div className='text-info'>Sent password reset. Please check your email</div>
                    </div>
                  )}

                  <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_password_reset_form'
                    onSubmit={formik.handleSubmit}
                  >
                    <br />
                    <input
                      type='email'
                      placeholder=''
                      autoComplete='off'
                      {...formik.getFieldProps('email')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid inputField-customizable',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                      )}
                      required={true}
                    />

                    <button
                      type='submit'
                      id='kt_password_reset_submit'
                      className='btn btn-lg btn-primary  submitButton-customizable'
                    >
                      <span className='indicator-label'>Reset my password</span>
                      {loading && (
                        <span className='indicator-progress'>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
