import {getLogger} from '../../../../_omicsbox/helpers/logUtils'
import {useCognitoAuth} from '../core/CognitoAuth'

export const ofuscateEmail = function (email: string) {
  const [user, domain] = email.split('@')

  return `${user[0]}***@${domain[0]}***`
}

function VerificationCodeInput(props: any = {onChange: () => {}, onComplete: () => {}}) {
  const {setVerificationCode} = useCognitoAuth()

  const saveConfirmationCode = (confirmationCode: string) => {
    // save confirmation code
    setVerificationCode(confirmationCode)

    getLogger().log('confirmationCode', confirmationCode)
  }

  const checkCode = (e: React.FormEvent<HTMLInputElement>): void => {
    let confirmationCode = e.currentTarget.value

    if (confirmationCode && confirmationCode.length === 6) {
      saveConfirmationCode(confirmationCode)

      props.onComplete(confirmationCode)
    }

    props.onChange(e)
  }

  return (
    <>
      <input type='password' autoComplete='off' {...props} maxlength='6' onChange={checkCode} />
    </>
  )
}
//TODO: add some title to indicate if it is email confirmation or password reset
export default VerificationCodeInput
