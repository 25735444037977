import {Auth} from 'aws-amplify'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {getLogger} from '../../../../_omicsbox/helpers/logUtils'
import {toastDefaultConfig} from '../../../../_omicsbox/toastConfig'
import {useCognitoAuth} from '../core/CognitoAuth'

const newPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  password: '',
}

function NewPassword() {
  const [loading, setLoading] = useState(false)
  const {verificationCode, currentUser} = useCognitoAuth()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: newPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      // call to forgotPasswordSubmit
      try {
        if (!verificationCode) {
          throw new Error('No verification code')
        }
        if (!currentUser) {
          throw new Error('User information missing!')
        }
        if (!currentUser.email) {
          throw new Error('email missing!')
        }
        const value = await Auth.forgotPasswordSubmit(
          currentUser.email,
          verificationCode,
          values.password
        )
        getLogger().log(value)
        navigate('/auth/login')
      } catch (e) {
        setStatus(e)
        setSubmitting(false)
        setLoading(false)
        //@ts-ignore
        toast(error.message, {...toastDefaultConfig, type: 'error'})
        getLogger().error(e)
      }
    },
  })
  return (
    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='password-reset-form'>
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <button
        type='submit'
        id='kt_sign_in_submit'
        className='btn btn-lg btn-primary w-100 mb-5'
        disabled={formik.isSubmitting || !formik.isValid}
      >
        {!loading && <span className='indicator-label'>Continue</span>}
        {loading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </form>
  )
}

export default NewPassword
