import {CognitoUser, CognitoUserAttribute} from 'amazon-cognito-identity-js'
import {CustomCognitoUser, UserAttributes} from './_models'

let userAttributes: UserAttributes = {}
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getCognitoAuth = (): CustomCognitoUser | undefined => {
  if (!localStorage) {
    return
  }

  const lsCognitoUser = localStorage.getItem('cognito-user')
  if (!lsCognitoUser) {
    return
  } else {
    return JSON.parse(lsCognitoUser) as CustomCognitoUser
  }
}

const setCognitoAuth = (auth: CustomCognitoUser) => {
  if (!localStorage) {
    return
  }
  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem('cognito-user', lsValue)
  } catch (error) {
    console.error('Local storage full. Proceeding to clear and retry', error)
    localStorage.removeItem('cognito-user')
  }
}

const removeCognitoAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem('cognito-user')
  } catch (error) {
    console.error('COGNITO USER REMOVE ERROR', error)
  }
}

const getHostedUIAuth = (): boolean | undefined => {
  if (!localStorage) {
    return false
  }

  try {
    return localStorage.getItem('amplify-redirected-from-hosted-ui') === 'true'
  } catch (error) {
    console.error('HOSTED UI AUTH ERROR', error)
  }
}

const removeHostedUIAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem('amplify-redirected-from-hosted-ui')
  } catch (error) {
    console.error('HOSTED UI AUTH ERROR', error)
  }
}

const mapCognitoAttributesToUserAttributes = async (
  cognitoUser: CognitoUser
): Promise<UserAttributes> => {
  var attributes: CognitoUserAttribute[] | undefined = []
  try {
    attributes = await getUserAttributeWrapper(cognitoUser)
  } catch (error) {
    throw new Error('Could not get user attributes')
  }
  userAttributes = {
    email: attributes!.find((attribute: any) => attribute.getName() === 'email')?.getValue() || '',
    name:
      attributes!.find((attribute: any) => attribute.getName() === 'given_name')?.getValue() || '',
    identity_id:
      attributes!
        .find((attribute: any) => attribute.getName() === 'custom:identityId')
        ?.getValue() || '',
    email_verified:
      attributes!.find((attribute: any) => attribute.getName() === 'email_verified')?.getValue() ===
      'true',
    sub: attributes!.find((attribute: any) => attribute.getName() === 'sub')?.getValue() || '',
    updated_at:
      attributes!.find((attribute: any) => attribute.getName() === 'updated_at')?.getValue() || '',
  }
  return userAttributes
}

const getUserAttributeWrapper = (cognitoUser: CognitoUser) =>
  new Promise<CognitoUserAttribute[] | undefined>((resolve, reject) => {
    cognitoUser.getUserAttributes((error, attributes) => {
      if (error) {
        reject(error)
      }
      resolve(attributes)
    })
  })

export {
  getCognitoAuth,
  setCognitoAuth,
  removeCognitoAuth,
  getHostedUIAuth,
  removeHostedUIAuth,
  mapCognitoAttributesToUserAttributes,
  AUTH_LOCAL_STORAGE_KEY,
}
