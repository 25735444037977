import {FC} from 'react'
import {ErrorBoundary, FallbackProps} from 'react-error-boundary'
import FileTable from '../modules/widgets/components/FileTable'

function ErrorFallback({error, resetErrorBoundary}: FallbackProps) {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}
function refreshPage() {
  window.location.reload()
}
export const FileViewer: FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={refreshPage}>
      <FileTable title='Cloud Files' />
    </ErrorBoundary>
  )
}
