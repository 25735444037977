import {uploadFile} from '@/app/modules/widgets/core/_requests'
import {toast} from 'react-toastify'
import {toastDefaultConfig} from '../toastConfig'
import {CustomFileData} from './crud-helper/models'
import {copylinksToClipboard} from './SystemHelpers'

function uploadFolder(folderContent: any, folderPath: string[]) {
  const files = Array.from(folderContent)
  const uploadPromises = files.map((file: any) => {
    const path = folderPath.slice(1, folderPath.length).join('/')
    const fullPath =
      path === '' ? `${file.webkitRelativePath}` : `${path}/${file.webkitRelativePath}`
    return uploadFile(fullPath, file)
  })
  return Promise.all(uploadPromises)
}

function copyLinkToClipboard(selectedFiles: CustomFileData[]) {
  let shareUrl: string[] = []

  selectedFiles.forEach((file: any) =>
    shareUrl.push(`${process.env.REACT_APP_SHARED_URL}${file.shareId}`)
  )

  copylinksToClipboard(shareUrl)

  toast('Copied link to clipboard!', toastDefaultConfig)
}

export {uploadFolder, copyLinkToClipboard}
