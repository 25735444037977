import {Auth} from '@aws-amplify/auth/lib-esm/Auth'
import {CognitoUser} from 'amazon-cognito-identity-js'
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {toast} from 'react-toastify'
import {getLogger} from '../../../../_omicsbox/helpers/logUtils'
import {LayoutSplashScreen} from '../../../../_omicsbox/layout/core'
import {toastDefaultConfig} from '../../../../_omicsbox/toastConfig'
import * as authHelper from './AuthHelpers'
import {mapCognitoAttributesToUserAttributes} from './AuthHelpers'
import {CustomCognitoUser, UserAttributes} from './_models'

type AuthContextProps = {
  cognitoAuth: CognitoUser | undefined
  saveCognitoAuth: (auth: CustomCognitoUser | undefined) => void
  currentUser: UserAttributes | undefined
  setCurrentUser: Dispatch<SetStateAction<UserAttributes | undefined>>
  cognitoLogout: () => void
  verificationCode: string | undefined
  setVerificationCode: Dispatch<SetStateAction<string | undefined>>
  confirmSignUp: boolean | undefined
  setConfirmSignUp: Dispatch<SetStateAction<boolean | undefined>>
}

const initAuthContextPropsState = {
  cognitoAuth: authHelper.getCognitoAuth(),
  saveCognitoAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  cognitoLogout: () => {},
  verificationCode: undefined,
  setVerificationCode: () => {},
  confirmSignUp: false,
  setConfirmSignUp: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useCognitoAuth = () => {
  return useContext(AuthContext)
}

const CognitoAuthProvider: FC<{children?: React.ReactNode; currentUserInit?: UserAttributes}> = ({
  children,
  currentUserInit,
}) => {
  const [cognitoAuth, setCognitoAuth] = useState<CognitoUser | undefined>(
    authHelper.getCognitoAuth()
  )
  const [currentUser, setCurrentUser] = useState<UserAttributes | undefined>(currentUserInit)
  const [verificationCode, setVerificationCode] = useState<string | undefined>()
  const [confirmSignUp, setConfirmSignUp] = useState<boolean | undefined>()
  const saveCognitoAuth = (auth: CustomCognitoUser | undefined) => {
    setCognitoAuth(auth)
    if (auth) {
      authHelper.setCognitoAuth(auth)
    } else {
      authHelper.removeCognitoAuth()
    }
  }

  const logout = async () => {
    saveCognitoAuth(undefined)
    setCurrentUser(undefined)
    authHelper.removeHostedUIAuth()
    try {
      await Auth.signOut()
    } catch (error) {
      //@ts-ignore
      toast(error.message, {...toastDefaultConfig, type: 'error'})
      getLogger().error(error)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        cognitoAuth,
        saveCognitoAuth,
        currentUser,
        setCurrentUser,
        cognitoLogout: logout,
        verificationCode,
        setVerificationCode,
        confirmSignUp,
        setConfirmSignUp,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const CognitoAuthInit: FC<{children?: React.ReactNode}> = ({children}) => {
  const {cognitoAuth, cognitoLogout, setCurrentUser, saveCognitoAuth} = useCognitoAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestCognitoUser = async () => {
      try {
        if (!didRequest.current) {
          const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser()
          const userAttributes = await mapCognitoAttributesToUserAttributes(cognitoUser)
          setCurrentUser(userAttributes)
          saveCognitoAuth(cognitoUser)
        }
      } catch (error) {
        if (!didRequest.current) {
          cognitoLogout()
        }

        //@ts-ignore
        toast(error.message, {...toastDefaultConfig, type: 'error'})
        getLogger().error(error)
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (cognitoAuth || authHelper.getHostedUIAuth()) {
      requestCognitoUser()
    } else {
      cognitoLogout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {CognitoAuthProvider, CognitoAuthInit, useCognitoAuth}
