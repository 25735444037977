import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import moment from 'moment'

import {HumanizeDuration, HumanizeDurationLanguage} from 'humanize-duration-ts'
import {Job, useFetchHistory} from '../../../../_omicsbox/helpers'

import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model'
import {ModuleRegistry} from '@ag-grid-community/core'
import {AgGridReact} from '@ag-grid-community/react' // the AG Grid React Component
import {MasterDetailModule} from '@ag-grid-enterprise/master-detail'
import {SetFilterModule} from '@ag-grid-enterprise/set-filter'

import '@ag-grid-enterprise/core'
import { LicenseManager } from '@ag-grid-enterprise/core'
import clsx from 'clsx'
import {collapseProps, collapsibleCard} from './external-viewer/collapsible-card'
import './style/ag-theme-metronic.scss'
import ReactTooltip from 'react-tooltip'

LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-049664 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( BioBam Bioinformatics S.L. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( OmicsBox Web )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( OmicsBox Web )_need_to_be_licensed___( OmicsBox Web )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 23 October 2024 )____[v2]_MTcyOTYzODAwMDAwMA==ba072e87b25bddc59335e28e6dfb7736")
ModuleRegistry.registerModules([ClientSideRowModelModule, MasterDetailModule, SetFilterModule])

function TableHistoryAg<T extends collapseProps>(props: T) {
  const todayDate = moment().format('yyyy-MM-DD')
  const backDate = moment().subtract(1, 'years').format('yyyy-MM-DD')

  const [filteredData, setFilteredData] = useState({
    startCreationTime: new Date(`${backDate}T00:00:00`).getTime(),
    endCreationTime: new Date(`${todayDate}T23:59:59`).getTime(),
  })
  const historyQuery = useFetchHistory(filteredData)
  const [rangeDate, setRangeDate] = useState({
    fromDate: backDate,
    toDate: todayDate,
  })
  const [filtering, setFiltering] = useState(false)
  const [isRangeValid, setRangeValid] = useState(true)
  const [showRangeFilter, setShowRangeFilter] = useState(false)
  const langService: HumanizeDurationLanguage = useMemo(() => new HumanizeDurationLanguage(), [])
  const humanizer: HumanizeDuration = useMemo(
    () => new HumanizeDuration(langService),
    [langService]
  )

  const gridRef = useRef<AgGridReact>(null) // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState<Job[]>() // Set rowData to Array of Objects, one Object per Row

  const masterDetail = true

  // Each Column Definition results in one Column.
  const [columnDefs] = useState([
    {
      colId: 'expandFake',
      valueGetter: (params: any) => {
        return ''
      },
      cellRenderer: 'agGroupCellRenderer',
      flex: 0.01,
      resizable: false,
    },
    {
      colId: 'Tag',
      headerName: 'Tag',
      cellClass: 'ag-cell-vMiddle',
      flex: 1,
      valueGetter: (params: any) => getTag(params.data),
      cellRenderer: (params: any) => {
        return <span className={`history_Tag tag_${params.value}`}>{params.value ?? ''}</span>
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      minWidth: 100
    },
    {
      headerName: 'Session Date',
      flex: 1,
      field: 'CreationTime',
      filter: false,
      cellRenderer: (params: any) => {
        return params.value ? moment(params.value).format('DD/MM/yyyy HH:mm:ss') : null
      },
      minWidth: 100
    },
    {headerName: 'Concept', field: 'serviceName', filter: false, minWidth: 200},
    {
      colId: 'Status',
      headerName: 'Status',
      valueGetter: (params: any) => {
        return `${params.data.countJobsDone} of ${params.data.countJobsStart} done${
          params.data.countJobsCancel ? `, ${params.data.countJobsCancel} canceled` : ''
        }${
          params.data.countJobsDone < params.data.countJobsStart
            ? `, ${params.data.countJobsStart - params.data.countJobsDone} pending`
            : ''
        }`
      },
      filter: false,
      flex: 1,
      minWidth: 150
    },
    {
      colId: 'Duration',
      headerName: 'Duration',
      valueGetter: (params: any) => {
        const diffMsec = params.data.lastStopTime - params.data.CreationTime

        return humanizer.humanize(diffMsec, {round: true})
      },
      filter: false,
      flex: 1,
      minWidth: 150
    },
    {
      headerName: 'Consumption',
      field: 'consumedUnits',
      filter: false,
      cellRenderer: (params: any) => {
        return `${params.value} units`
      },
      minWidth: 100
    },
    {
      headerName: 'Charged',
      field: 'billedUnits',
      filter: false,
      cellRenderer: (params: any) => {
        return `${params.value} units`
      },
      minWidth: 100
    },
  ])

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: [{field: 'Parameter'}, {field: 'Value'}],
        defaultColDef: {
          flex: 1,
          resizable: false,
        },
      },
      getDetailRowData: (params: any) => {
        const paramDetails = Object.entries(JSON.parse(params.data.parameters)).map(
          ([key, value]: [any, any]) => ({Parameter: key, Value: value})
        )

        params.successCallback(paramDetails)
      },
    }
  }, [])

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      wrapText: true,
      autoHeight: true,
    }),
    []
  )

  useEffect(() => {
    if (historyQuery.data) {
      setRowData(historyQuery.data)

      if (gridRef.current?.api) {
        gridRef.current.api.hideOverlay()
      }

      setFiltering(false)
    }
  }, [historyQuery.data])

  const FilterTable = useCallback(() => {
    if (rangeDate.fromDate && rangeDate.toDate) {
      const fromTimestamp = new Date(`${rangeDate.fromDate}T00:00:00`).getTime()
      const toTimestamp = new Date(`${rangeDate.toDate}T23:59:59`).getTime()
      const rangeValid = toTimestamp >= fromTimestamp

      setRangeValid(rangeValid)

      if (
        rangeValid &&
        (filteredData.startCreationTime !== fromTimestamp ||
          filteredData.endCreationTime !== toTimestamp)
      ) {
        setFilteredData({
          startCreationTime: fromTimestamp,
          endCreationTime: toTimestamp,
        })

        if (gridRef.current) {
          gridRef.current.api.showLoadingOverlay()
        }

        historyQuery.refetch()
      }
    }

    setFiltering(false)
  }, [filteredData, historyQuery, rangeDate])

  useEffect(() => {
    if (filtering === true) {
      FilterTable()
    }
  }, [FilterTable, filtering])

  // TODO: this should be moved to a helper
  const getTag = useCallback((rowData: Job) => {
    if (rowData.countJobsError > 0) return 'Error'

    if (rowData.countJobsCancel > 0) return 'Canceled'

    if (rowData.countJobsDone < rowData.countJobsStart) return 'Running'

    return 'Done'
  }, [])

  const ResetFilterTable = useCallback(() => {
    setRangeDate({
      fromDate: backDate,
      toDate: todayDate,
    })

    setFiltering(true)
  }, [backDate, todayDate, setFiltering])

  const toggleFilter = () => setShowRangeFilter((value) => !value)

  return (
    <div className='ag-theme-metronic card mb-5 w-100 h-100 mb-xl-8'>
      <div className='card-header border-0 pt-5' style={{minHeight: 110}}>
        <div className='w-100 d-flex p-0 justify-content-between align-items-start'>
          <h2>{props.title}</h2>
          <button {...props.collapseProps} className='collapse-icon collapse-icon-top'>
            <i className='fa fa-window-minimize fs-5'></i>
          </button>
        </div>
        <div className='agHistory_filter'>
          <div className={clsx({'d-none': !showRangeFilter})}>
            <label htmlFor='th-filter-start'>From:</label>
            <input
              className='ms-2 me-5'
              type='date'
              id='th-filter-start'
              name='range-start'
              onChange={(event) => setRangeDate({...rangeDate, fromDate: event.target.value})}
              value={rangeDate.fromDate}
              min='2012-01-01'
              max={todayDate}
            ></input>
            <label htmlFor='th-filter-end'>To:</label>
            <input
              className='ms-2'
              type='date'
              id='th-filter-end'
              name='range-end'
              onChange={(event) => setRangeDate({...rangeDate, toDate: event.target.value})}
              value={rangeDate.toDate}
              min='2012-01-01'
              max={todayDate}
            ></input>
            {
              <button className='ms-2 btn btn-primary btn-sm' onClick={ResetFilterTable}>
                Reset
              </button>
            }
            {
              <button
                className='ms-2 btn btn-primary btn-sm'
                onClick={() => {
                  setFiltering(true)
                  FilterTable()
                }}
                disabled={!isRangeValid}
              >
                Filter
              </button>
            }
          </div>
          <button
            className='nav-link btn btn-sm fw-bolder px-4 me-1'
            data-bs-toggle='tab'
            data-tip
            data-for='toggle-filter'
            onClick={toggleFilter}
          >
            <i className='fas fa-calendar'></i>
          </button>
          <ReactTooltip id='toggle-filter' effect='solid'>
            <span>Toggle date filter</span>
          </ReactTooltip>
        </div>
      </div>

      <div className='card-body pb-3 pt-0' style={{overflow: 'scroll', height: '425px'}}>
        <div className='table-responsive' style={{display: 'unset'}}>
          <AgGridReact
            ref={gridRef}
            masterDetail={masterDetail}
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection='multiple' // Options - allows click selection of rows
            detailCellRendererParams={detailCellRendererParams}
            tooltipShowDelay={500}
          />
        </div>
      </div>
    </div>
  )
}

export default collapsibleCard(TableHistoryAg)
